import { Grid } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { BudgetSendBtn, SmallLinkedButton } from './budgetButton';
import { BasicTable } from './table';
import formatters from '../Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../Controls/ScreenResolution';
import { SvgTickSVG } from '../../Components/internal/svgticks'
import './budget.scss';
import React from 'react';

const setPercentage = (profit, budget) => {
    if (!profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value) <= 100 ? Math.round(value) : 100;
        }
    }
};

const setPercentageOverHundred = (profit, budget) => {
    if (!profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value);
        }
    }
};

const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

//This comment refers to the 'Branch Overview' component.

export const DepartmentCard = (props) => {
    const { profit, budget, labour, showLink } = props;
    const history = useHistory();
    const sendLink = () => {
        history.push({
            pathname: props.url,
            state: props.type || ''
        });
    };

    return (
        <Grid container style={{ textAlign: 'justify', }} justifyContent="space-around" alignItems="center" className="BOprofitloss_Card">
            <Grid item xs={4}>
                <div className={showLink ? 'BOringPropertyAfterSale' : 'BOringProperty'}>
                    <CircularProgressbar
                        value={setPercentage(profit, budget)}
                        text={`${setPercentage(profit, budget) || 0}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(profit, budget) >= 75
                                            ? '#569101'
                                            : '#D92641',
                            pathColor:
                                setPercentage(profit, budget) < 50
                                    ? '#D92641'
                                    : setPercentage(profit, budget) >= 50 && setPercentage(profit, budget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(profit, budget) >= 75
                                            ? '#569101'
                                            : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item xs={7}>
                <div className="BOsecondaryHeading" style={{ padding: '0px 0px 12px 0px', marginTop: showLink ? -13 : 0 }}>
                    {props.dept}
                </div>
                <div
                    style={{
                        marginBottom: 5,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <div className="BOtype3_Text w50">Total {labour ? 'Labour' : 'Profit'}</div>
                    <div className="BOtype1_Text alignLeft w50">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="BOtype3_Text alignLeft w50">Total Budget</div>
                    <div className="BOtype1_Text alignLeft w50">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
            <Grid item xs={1} style={{ alignSelf: 'self-start' }}>
                {showLink ? (
                    <span
                        style={{
                            padding: '6px 10px 0px 0px',
                            float: 'right'
                        }}
                    >
                        <BudgetSendBtn onClick={sendLink} />
                    </span>
                ) : null}
            </Grid>
        </Grid>
    );
};

export const DepartmentBudgetCard = (props) => {
    const [width] = useWindowSize();
    const history = useHistory();
    const { icon, value, label, profitVal, showLink, est_profit } = props;
    const sendLink = () => {
        history.push({
            pathname: props.url,
            state: props.type || ''
        });
    };

    return (
        <div className="BObranchOverViewKip_card" style={{ margin: '12px 6px' }}>
            {showLink ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '7px 0px'
                    }}
                >
                    <BudgetSendBtn onClick={sendLink} />
                </div>
            ) : null}

            <div className="BOtype1_Text" style={{ paddingBottom: '4px' }}>
                {value || 0}
            </div>
            <div className="BOtype2_Text">{label || 'N/A'}</div>

            {!props.hideBudget ? (
                <>
                    <div className="BOtype3_Text" style={{ paddingTop: width > 600 ? '12px' : '6px' }}>
                        BUDGET
                    </div>
                    <div
                        className="BOtype1_Text p4"
                        style={{
                            color: icon == 'up' ? '#569101' : '#E51A54'
                        }}
                    >
                        <span>{profitVal || 0}</span>
                        <span style={{ verticalAlign: 'middle' }}>
                            {icon === 'down' ? <ArrowDownwardIcon className="BOtype2_Text" /> : <ArrowUpwardIcon className="BOtype2_Text" />}
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className="BOtype3_Text" style={{ paddingTop: width > 600 ? '12px' : '6px' }}>
                        EST. PROFIT
                    </div>
                    <div className="BOtype1_Text p4" style={{ color: est_profit >= 0 ? '#569101' : '#E51A54' }}>
                        <span>{formatters.CurrencyThousandSeparatorWithoutZero(est_profit) || 0}</span>
                    </div>
                </>
            )}
        </div>
    );
};

//This comment refers to the 'Profit and Loss' component.

export const ProfitLossCards = (props) => {
    const [WindowWidths] = useWindowSize();

    const { totalCalProfit, profit, budget, branchName, retailsUnits, motabilityUnits, tradeUnits, newUnits, usedUnits, branchID } = props;
    return (
        <Grid
            container
            justifyContent={WindowWidths <= 600 ? 'space-between' : 'space-around'}
            alignItems="center"
            className="SPnLprofitloss_Card"
            style={{ padding: '0px 10px ' }}
        >
            <Grid item xs={12}>
                <Grid container>
                    <Grid
                        xs
                        item
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className="SPnLsecondaryHeading p4">{branchName}</div>
                    </Grid>
                    {props.selectedFilterBtnId === 'New' || props.selectedFilterBtnId === 'Used' ? (
                        <Grid item>
                            <BudgetSendBtn onClick={() => props.onRedirectToNewUnits(branchID, props.selectedFilterBtnId)} />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <Grid item>
                <div className="PanLringProperty">
                    <CircularProgressbar
                        value={totalCalProfit}
                        text={`${totalCalProfit}%`}
                        styles={buildStyles({
                            textColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                        ? '#ff9f00'
                                        : totalCalProfit >= 75
                                            ? '#569101'
                                            : '#D92641',
                            pathColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                        ? '#ff9f00'
                                        : totalCalProfit >= 75
                                            ? '#569101'
                                            : '#D92641',
                            textSize: '27px',
                            dominantBaseline: 'central'
                        })}
                    />
                </div>
            </Grid>
            <Grid item>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="SPnLtype3_Text alignLeft ">Total Profit</div>
                    <div className="SPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    <div className="SPnLtype3_Text alignLeft ">Total Budget</div>
                    <div className="SPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
            {props.selectedFilterBtnId === 'New' || props.selectedFilterBtnId === 'Used' ? (
                <Grid item>
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="SPnLtype3_Text alignLeft ">Retail Units</div>
                        <div className="SPnLtype1_Text  alignLeft">{formatters.ThousandSeparatorWithoutZero(retailsUnits)}</div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="SPnLtype3_Text alignLeft ">{props.selectedFilterBtnId === 'New' ? 'Motability Units' : 'Trade Units'}</div>
                        <div className="SPnLtype1_Text  alignLeft">
                            {props.selectedFilterBtnId === 'New'
                                ? formatters.ThousandSeparatorWithoutZero(motabilityUnits)
                                : formatters.ThousandSeparatorWithoutZero(tradeUnits)}
                        </div>
                    </div>
                </Grid>
            ) : (
                <Grid item style={{ margin: '10px 0px' }}>
                    <div style={{ marginBottom: 10 }}>
                        <Grid item xs>
                            <div className="SPnLtype3_Text alignLeft">New Units &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <div className="SPnLtype1_Text alignLeft"> {formatters.ThousandSeparatorWithoutZero(newUnits)}</div>
                            </Grid>
                            <Grid item>
                                <div className="SPnLtype3_Text alignLeft">
                                    <BudgetSendBtn onClick={() => props.onRedirectToNewUnits(branchID, 'New')} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid item xs>
                            <div className="SPnLtype3_Text alignLeft">Used Units &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <div className="SPnLtype1_Text alignLeft"> {formatters.ThousandSeparatorWithoutZero(usedUnits)}</div>
                            </Grid>
                            <Grid item>
                                <div className="SPnLtype3_Text alignLeft">
                                    <BudgetSendBtn onClick={() => props.onRedirectToNewUnits(branchID, 'Used')} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

//This comment refers to the 'Aftersale Profit and Loss' component.

export const ASalePandLCard = (props) => {
    const { totalCalProfit, profit, budget, branchID } = props;
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="ASPnLprofitloss_Card">
            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div className="ASPnLsecondaryHeading p4">{props.label}</div>
                    <BudgetSendBtn onClick={() => props.onRedirectFun(branchID)} />
                </div>
            </Grid>
            <Grid item>
                <div className="PanLringProperty">
                    <CircularProgressbar
                        value={totalCalProfit}
                        text={`${totalCalProfit}%`}
                        styles={buildStyles({
                            textColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                        ? '#ff9f00'
                                        : totalCalProfit >= 75
                                            ? '#569101'
                                            : '#D92641',
                            pathColor:
                                totalCalProfit < 50
                                    ? '#D92641'
                                    : totalCalProfit >= 50 && totalCalProfit < 75
                                        ? '#ff9f00'
                                        : totalCalProfit >= 75
                                            ? '#569101'
                                            : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item style={{ margin: 10 }}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="ASPnLtype3_Text alignLeft ">TOTAL LABOUR</div>
                    <div className="ASPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(profit)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    <div className="ASPnLtype3_Text alignLeft ">Total Budget</div>
                    <div className="ASPnLtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(budget)}</div>
                </div>
            </Grid>
        </Grid>
    );
};

//This refers to 'Site Performance'
export const TotalUnitsSitePerformance = (props) => {
    const { value, label, profitVal, budgetLable, previousYear, valueType, mainType, lowerLabel, valueCalc, valueSec, removeBrad, removePrevious, mainValueTooltip, averageTooltip, priorYearTooltip } = props;
    let increasedPriorYear = 0

    if (valueCalc) {
        if (previousYear < 0) {
            increasedPriorYear = Math.round((((valueSec) + (Math.abs(previousYear))) / (Math.abs(previousYear)))*100);
        }
        else {
            increasedPriorYear = Math.round((((valueSec) - (previousYear)) / (previousYear))*100);
        }
    }
    else {
        if (previousYear < 0) {
            increasedPriorYear = Math.round((((value) + (Math.abs(previousYear))) / (Math.abs(previousYear)))*100);
        }
        else {
            increasedPriorYear = Math.round((((value) - (previousYear)) / (previousYear))*100);
        }
    }
    

    let percentageIncreased = increasedPriorYear;

    let percentageIncreasedSign = percentageIncreased < 0 ? "-" : "+";
    
    return (
        <div className={mainType ? 'TotalUnitSP' : 'TotalUnitSP normalType'}>
            <span className={value < 0 || profitVal > value ? 'mainValue redValue tooltipWrp' : 'mainValue tooltipWrp'}>{valueType === "currency" ? formatters.CurrencyThousandSeparatorWithoutZero(value) : value}
                {
                    mainValueTooltip != "" ? <>
                        <div className='tooltipBox'>{mainValueTooltip}</div>
                    </> : <></>
                }
            </span>
            <span className='mainLabel'>{label}</span>
            <span className='loweLabel'>{lowerLabel}</span>
            <div className='compValues'>
                {
                    removeBrad ? <></> : <>
                        <span className='avgValue tooltipWrp'>
                            {
                                profitVal > value ? <>
                                    <SvgTickSVG type="negativeTick" />
                                </> : <>
                                    <SvgTickSVG type="positiveTick" />
                                </>
                            }
                            {budgetLable} <span className={'black'}>{valueType === "currency" ? formatters.CurrencyThousandSeparatorWithoutZero(profitVal) : profitVal }</span>
                            {
                                averageTooltip != "" ? <>
                                    <div className='tooltipBox'>{averageTooltip}</div>
                                </> : <></>
                            }
                        </span>
                    </>
                }
                {
                    removePrevious ? <></> : <>
                    <span className={percentageIncreasedSign === '-' ? 'priorYear redValue tooltipWrp' : 'priorYear tooltipWrp'}>
                        {
                            percentageIncreasedSign === '-' ? <>
                                <SvgTickSVG type="negativeArrow" />
                            </> : <>
                                <SvgTickSVG type="positiveArrow" />
                            </>
                        }
                        {percentageIncreased + "% "}
                        prior year
                        {
                            priorYearTooltip != "" ? <>
                                <div className='tooltipBox'>{priorYearTooltip}</div>
                            </> : <></>
                        }
                    </span>
                    </>
                }
            </div>
        </div>
    );
};

export const ProgressBarSitePerformance = (props) => {
    const { value, title, value1, title1, value2, title2, title3, value1Tooltip, value2Tooltip, value3Tooltip } = props;
    const percentage1 = Math.round((value1 * 100) / value);
    const percentage2 = Math.round((value2 * 100) / value);
    const percentage3 = 100 - percentage1 - percentage2;
    const motabValue = Math.round(value - value1 - value2);

    return (
        <div className='progressBarSPwrp'>
            <span className='mainTitle'>{title}</span>
            <div className='progressBarSP'>
                <div className="progressBar pb-left" style={{ width: percentage1 + "%" }}>
                    <div className="progressBartooltip">
                        <span>{percentage1 + "%"}</span>
                    </div>
                </div>
                <div className="progressBar pb-mid" style={{ width: percentage3 + "%" }}>
                    <div className="progressBartooltip">
                        <span>{percentage3 + "%"}</span>
                    </div>
                </div>
                <div className="progressBar pb-right" style={{ width: percentage2 + "%" }}>
                    <div className="progressBartooltip">
                        <span>{percentage2 + "%"}</span>
                    </div>
                </div>
            </div>
            <div className='progressBarSPTitles'>
                <span className='barTitles tooltipWrp'><span className='titleInitial'>{Array.from(title1)[0]}</span> {value1}<span> {title1}</span>
                {
                    value1Tooltip != "" ? <>
                        <div className='tooltipBox'>{value1Tooltip}</div>
                    </> : <></>
                }
                </span>
                <span className='barTitles tooltipWrp'><span className='titleInitial'>{Array.from(title3)[0]}</span> {motabValue}<span> {title3}</span>
                {
                    value3Tooltip != "" ? <>
                        <div className='tooltipBox'>{value3Tooltip}</div>
                    </> : <></>
                }
                </span>
                <span className='barTitles tooltipWrp'><span className='titleInitial'>{Array.from(title2)[0]}</span> {value2}<span> {title2}</span>
                {
                    value2Tooltip != "" ? <>
                        <div className='tooltipBox'>{value2Tooltip}</div>
                    </> : <></>
                }
                </span>
            </div>
        </div>
    );
}

export const SummaryBreakDownSitePerformance = (props) => {
    const [WindowWidths] = useWindowSize();
    const { title, cases, casesTitle, avg, avgTitle, value, valueTitle, pen, casesTooltip, averageTooltip, profitTooltip, penTooltip } = props;

    return (
        <div className='summaryBreakdownItem'>
            {
                WindowWidths < 768 ? <>
                    <div className='summaryBreakdownMob'>
                        <span className='summaryTitle'>{title}</span>
                        <div className='summaryCases tooltipWrp'>
                            <span>{cases}</span>
                            <span>{casesTitle}</span>
                            {
                                casesTooltip != "" ? <>
                                    <div className='tooltipBox'>{casesTooltip}</div>
                                </> : <></>
                            }
                        </div>
                        <div className='summaryValue tooltipWrp'>
                            <span>{valueTitle}</span>
                            <span>{formatters.CurrencyThousandSeparatorWithoutZero(value)}</span>
                            {
                                profitTooltip != "" ? <>
                                    <div className='tooltipBox'>{profitTooltip}</div>
                                </> : <></>
                            }
                        </div>
                    </div>
                </> : <>
                    <div className='summaryBreakdownDesk'>
                        <div className='summaryBreakdownTop'>
                            <span className='summaryTitle'>{title}</span>
                            <div className='summaryCases tooltipWrp'>
                                <span>{cases}</span>
                                <span>{casesTitle}</span>

                                {
                                    casesTooltip != "" ? <>
                                        <div className='tooltipBox'>{casesTooltip}</div>
                                    </> : <></>
                                }
                            </div>
                            {
                                title === "Admin / Fuel" ? <>
                                    <div className='summaryAvg'>
                                        <span>&nbsp;</span>
                                    </div>
                                </> : <>
                                    <div className='summaryAvg'>
                                        {/* <span>{avgTitle}</span>
                                        <span>{avg}</span> */}
                                        <span>&nbsp;</span>
                                        <span>&nbsp;</span>
                                    </div>
                                </>
                            }
                            <div className="summarySvg">
                                <SvgTickSVG type={title === "Admin / Fuel" ? "Accessories" : title === "CSI" ? "Warranty" : title } />
                            </div>
                        </div>
                        <div className='summaryBreakdownBtm'>
                            <div className='summaryValue tooltipWrp'>
                                {
                                    title === "CSI" ? <>
                                        <span>&nbsp;</span>
                                    </> : <>
                                        <span>{formatters.CurrencyThousandSeparatorWithoutZero(value)}</span>
                                    </>
                                }
                                <span>{valueTitle}</span>
                                {
                                    profitTooltip != "" ? <>
                                        <div className='tooltipBox'>{profitTooltip}</div>
                                    </> : <></>
                                }
                            </div>
                            <div className='summaryPenetration tooltipWrp'>
                                {
                                    title === "Products" ? 
                                    <>
                                        <span>&nbsp; </span>
                                        <span style={{width: 0+"%", margin: 0}}></span>
                                        <span><span style={{fontWeight: "normal", float: "inline-start", marginRight: "10px"}}>PPS</span> {pen}</span>
                                    </> :
                                    title === "CSI" ? 
                                    <> 
                                        <span>COMING SOON</span>
                                        <span style={{width: 0}}>&nbsp;</span>
                                        <span>&nbsp;</span>
                                    </> :
                                    title === "Admin / Fuel" ? 
                                    <> 
                                        <span>ACCESSORIES</span>
                                        <span style={{width: 0}}>&nbsp;</span>
                                        <span>&nbsp;</span>
                                    </> :
                                    <>
                                        <span>Penetration</span>
                                        {
                                            pen >= 70 ? <>
                                                <span style={{width: 70+"%"}}></span>
                                            </> : <>
                                                <span style={{width: (pen*0.7)+"%"}}></span>
                                            </>
                                        }
                                        <span>{pen}%</span>
                                    </>
                                }
                                {
                                    penTooltip != "" ? <>
                                        <div className='tooltipBox'>{penTooltip}</div>
                                    </> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export const UnitValuesSitePerformance = (props) => {
    const { mainValue, mainTitle, averageValue, averageValueType, averageTitle, averageDisplayValue, colourMainRed, hideAverage, mainAgainstPriorValue, mainAgainstPriorTitle, mainType, mainValueType, linkTitle, link, pen, mainValueTooltip, averageTooltip, priorYearTooltip, penTooltip } = props;
    let mainValueCalc, mainTitleEdited, percentagePrevious, percentageIncreased, percentageIncreasedSign;
    let count = 0;
    const history = useHistory();
    let invType = false;

    if (mainAgainstPriorValue != undefined) {
        percentagePrevious = Math.round((mainValue * 100) / mainAgainstPriorValue);
        percentageIncreased = percentagePrevious < 100 ? 100 - percentagePrevious : percentagePrevious - 100;
        percentageIncreasedSign = percentagePrevious < 100 ? "-" : "+";
    }

    if (mainValue !== null && typeof (mainValue) === "object") {
        if (mainValue.length !== 0) {
            if (mainTitle === "Average DIS") {
                invType = true;
                mainValueCalc = mainValue.reduce(function (tot, el) {
                    count++;
    
                    return tot += el.avgDis;
                }, 0);
            }
            else if (mainTitle === "Overaged (90+)") {
                invType = true;
                mainValueCalc = mainValue.reduce(function (tot, el) {
                    count++;
    
                    return tot += el.totalPercPlus90;
                }, 0);
            }
            else if (mainTitle === "Stock Turn") {
                mainValueCalc = mainValue.reduce(function (tot, el) {
                    count++;
    
                    return tot += el.stockTurn;
                }, 0);
            }

            mainValueCalc = Math.round(mainValueCalc / count);
            mainTitleEdited = mainTitle;
        }
        else {
            mainValueCalc = 0;
            mainTitleEdited = mainTitle || "";
        }
    }
    else {
        mainValueCalc = mainValue === null ? 0 : mainValue;
        mainTitleEdited = mainTitle || "";
    }

    if (mainTitle === "Mechanical Prep" || mainTitle === "Bodywork") {
        invType = true;
    }

    const handleRedirect = (e, link) => {
        e.preventDefault();

        history.push({
            pathname: link,
        });
    };

    var penType = mainTitleEdited === "Supagard" || mainTitleEdited === "Service Plan" ? "SUPAGARD" : "SMART36";

    return (
        <>
            <div className={mainType ? 'unitValueSP mainType' : 'unitValueSP normalType' }>
                {
                    mainValueType === "money" ? <>
                        <span className={invType ? pen != null ? parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) > parseFloat(averageValue) ? 'mainValue wpenLine redValue tooltipWrp' : 'mainValue wpenLine tooltipWrp' : parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) > parseFloat(averageValue)  ? 'mainValue redValue tooltipWrp' : 'mainValue tooltipWrp' :
                        pen != null ? parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) < parseFloat(averageValue) ? 'mainValue wpenLine redValue tooltipWrp' : 'mainValue wpenLine tooltipWrp' : parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) < parseFloat(averageValue)? 'mainValue redValue tooltipWrp' : 'mainValue tooltipWrp tooltipWrp'}>{formatters.CurrencyThousandSeparatorWithoutZero(mainValueCalc)}
                            {
                                mainValueTooltip != "" ? <>
                                    <div className='tooltipBox'>{mainValueTooltip}</div>
                                </> : <></>
                            }
                        </span>
                        <span className='mainTitle'>{mainTitleEdited}</span>
                        <span className='val1Line tooltipWrp'>
                            {
                                hideAverage ? <></> : <>
                                {
                                    averageValue !== "N/A" && mainType ? 
                                    invType ?
                                    parseFloat(averageValue) < parseFloat(mainValueCalc) ? 
                                        <>
                                            <SvgTickSVG type="negativeTick" />
                                        </> : <>
                                            <SvgTickSVG type="positiveTick" />
                                        </>
                                    :
                                    parseFloat(averageValue) > parseFloat(mainValueCalc) ? 
                                        <>
                                            <SvgTickSVG type="negativeTick" />
                                        </> : <>
                                            <SvgTickSVG type="positiveTick" />
                                        </>
                                    : ""
                                }
                                {averageTitle+" " || " "} 
                                <span className={'black'}>
                                    {averageValue == "N/A" ? 0 : formatters.CurrencyThousandSeparatorWithoutZero(averageValue)}
                                </span>
                                {
                                    averageTooltip != "" ? <>
                                        <div className='tooltipBox'>{averageTooltip}</div>
                                    </> : <></>
                                }
                                </>
                            } 
                        </span>
                        {
                            linkTitle != "" ? <>
                                <span className='linkRedirect' onClick={e => handleRedirect(e, link)}>{linkTitle}</span>
                            </> : ""
                        }
                    </> :
                        mainValueType === "percentage" ? <>
                            <span className={invType ? pen != null ? parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) > parseFloat(averageValue) ? 'mainValue wpenLine redValue tooltipWrp' : 'mainValue wpenLine tooltipWrp' : parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) > parseFloat(averageValue)  ? 'mainValue redValue tooltipWrp' : 'mainValue tooltipWrp' :
                        pen != null ? parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) < parseFloat(averageValue) ? 'mainValue wpenLine redValue tooltipWrp' : 'mainValue wpenLine tooltipWrp' : parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) < parseFloat(averageValue)? 'mainValue redValue tooltipWrp' : 'mainValue tooltipWrp'}>{mainValueCalc + '%'}
                            {
                                mainValueTooltip != "" ? <>
                                    <div className='tooltipBox'>{mainValueTooltip}</div>
                                </> : <></>
                            }
                        </span>
                            <span className={pen != null ? "mainTitle wpenLine" : "mainTitle"}>{mainTitleEdited}</span>
                            {
                                pen != null ? <>
                                    <span className='penValue tooltipWrp'><span>{pen}</span>
                                        {
                                            penTooltip != "" ? <>
                                                <div className='tooltipBox'>{penTooltip}</div>
                                            </> : <></>
                                        }
                                    </span>
                                </> : <></>
                            }
                            <span className={pen != null ? "val1Line wpenLine tooltipWrp" : "val1Line tooltipWrp"}>
                                {
                                    averageValue !== "N/A" && mainType ? 
                                    invType ?
                                    parseFloat(averageValue) < parseFloat(mainValueCalc) ? 
                                        <>
                                            <SvgTickSVG type="negativeTick" />
                                        </> : <>
                                            <SvgTickSVG type="positiveTick" />
                                        </>
                                    :
                                    parseFloat(averageValue) > parseFloat(mainValueCalc) ? 
                                        <>
                                            <SvgTickSVG type="negativeTick" />
                                        </> : <>
                                            <SvgTickSVG type="positiveTick" />
                                        </>
                                    : ""
                                }
                                {averageTitle+" " || ""} <span className={'black'}> {averageValue == "N/A" ? 0 : averageValue + '%'}</span>
                                {
                                    averageTooltip != "" ? <>
                                        <div className='tooltipBox'>{averageTooltip}</div>
                                    </> : <></>
                                }
                                </span>
                            {
                                linkTitle != "" ? <>
                                    <span className='linkRedirect' onClick={e => handleRedirect(e, link)}>{linkTitle}</span>
                                </> : ""
                            }
                        </> : <>
                            <span className={
                                invType ? 
                                    pen != null ? 
                                    parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) > parseFloat(averageValue) ? 
                                            'mainValue wpenLine redValue tooltipWrp' 
                                        : 
                                            'mainValue wpenLine tooltipWrp' 
                                    : 
                                    parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) > parseFloat(averageValue)  ? 
                                            'mainValue redValue tooltipWrp' 
                                        : 
                                            'mainValue tooltipWrp' 
                                :
                                    pen != null ? 
                                    parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) < parseFloat(averageValue) ? 
                                            'mainValue wpenLine redValue tooltipWrp' 
                                        : 
                                            'mainValue wpenLine tooltipWrp' 
                                    : 
                                    parseFloat(mainValueCalc) < 0 || parseFloat(mainValueCalc) < parseFloat(averageValue) ? 
                                            'mainValue redValue tooltipWrp' 
                                        : 
                                            'mainValue tooltipWrp'
                                }><span className={colourMainRed ? "colourRed" : mainTitle == "Trade" ? "colourBlack" : ""}>{isNaN(mainValueCalc) ? 0 : mainValueCalc}</span>
                                {
                                    mainValueTooltip !== "" ? <>
                                        <div className='tooltipBox'>{mainValueTooltip}</div>
                                    </> : <></>
                                }
                                </span>
                            <span className={pen != null ? "mainTitle wpenLine" : "mainTitle"}>{mainTitleEdited}</span>
                            {
                                pen != null ? <>
                                    <span className={colorMatch(penType, pen) + " penValue tooltipWrp"}><span>{pen + "%"}</span>{
                                            penTooltip != "" ? <>
                                                <div className='tooltipBox'>{penTooltip}</div>
                                            </> : <></>
                                        }</span>
                                </> : <></>
                            }
                            {
                                hideAverage ? <></> : <>
                                    <span className={pen != null ? "val1Line wpenLine tooltipWrp" : "val1Line tooltipWrp"}>
                                {
                                    averageValue !== "N/A" && mainType ? 
                                        invType ?
                                        parseFloat(averageValue) < parseFloat(mainValueCalc) ? 
                                                <>
                                                    <SvgTickSVG type="negativeTick" />
                                                </> : <>
                                                    <SvgTickSVG type="positiveTick" />
                                                </>
                                            :
                                            parseFloat(averageValue) > parseFloat(mainValueCalc) ? 
                                                <>
                                                    <SvgTickSVG type="negativeTick" />
                                                </> : <>
                                                    <SvgTickSVG type="positiveTick" />
                                                </>
                                    : ""
                                }
                                {averageTitle+" " || ""} <span className={'black'}> {averageValueType === "percentage" ? averageDisplayValue : isNaN(averageValue) ? 0 : averageValue}</span>
                                {
                                    averageTooltip != "" ? <>
                                        <div className='tooltipBox'>{averageTooltip}</div>
                                    </> : <></>
                                }
                                </span>
                                    </> 
                            }
                            {
                                linkTitle != "" ? <>
                                    <span className='linkRedirect' onClick={e => handleRedirect(e, link)}>{linkTitle}</span>
                                </> : ""
                            }
                        </>
                }
                {
                    mainAgainstPriorValue ? <>
                        <span className={percentageIncreasedSign === "-" ? 'val2Line redValue' : 'val2Line'}>{percentageIncreasedSign + percentageIncreased + "% "}{mainAgainstPriorTitle}</span>
                    </> : ""}
            </div>
        </>
    );
}

export const SummaryBoxNav = (props) => {
    const { title1, clickHandler1, id1, title2 , id2, title3 , id3, title4 , id4, title5 , id5, itemOpen } = props;
    return (
        <div className='summaryBoxNav'>
            <div className={itemOpen === id1 ? 'summaryBoxItem summaryOpen' : 'summaryBoxItem'} onClick={() => clickHandler1(id1)}>
                <div className="summaryBoxTitle">
                    <SvgTickSVG type={title1} />
                    <span>{title1}</span>
                </div>
            </div>
            <div className={itemOpen === id2 ? 'summaryBoxItem summaryOpen' : 'summaryBoxItem'} onClick={() => clickHandler1(id2)}>
                <div className="summaryBoxTitle">
                    <SvgTickSVG type={title2} />
                    <span>{title2}</span>
                </div>
            </div>
            <div className={itemOpen === id3 ? 'summaryBoxItem summaryOpen' : 'summaryBoxItem'} onClick={() => clickHandler1(id3)}>
                <div className="summaryBoxTitle">
                    <SvgTickSVG type={title3} />
                    <span>{title3}</span>
                </div>
            </div>
            {
                id4 !== "doNothing" ?
                <>
                    <div className={itemOpen === id4 ? 'summaryBoxItem summaryOpen' : 'summaryBoxItem'} onClick={() => clickHandler1(id4)}>
                        <div className="summaryBoxTitle">
                            <SvgTickSVG type={title4} />    
                            <span>{title4}</span>
                        </div>
                    </div>
                </> : <>
                <div className={itemOpen === id4 ? 'summaryBoxItem summaryOpen' : 'summaryBoxItem'}>
                    <div className="summaryBoxTitle">
                        <SvgTickSVG type={title4 === "CSI" ? "Warranty" : title4} />    
                        <span>{title4}</span>
                    </div>
                </div>
                </>
            }
            <div className={itemOpen === id5 ? 'summaryBoxItem summaryOpen' : 'summaryBoxItem'} onClick={() => clickHandler1(id5)}>
                <div className="summaryBoxTitle">
                    <SvgTickSVG type={title5 == "Others" ? "Accessories" : title5} />
                    <span>{title5}</span>
                </div>
            </div>
        </div>
    );
}

export const colorMatch = (type, number) => {
    var colourMatchString = "";

    switch(type) {
        case "SUPAGARD":
        case "SVPM12":
        case "SVPM12DD":
        case "SVPM24":
        case "SVPM24DD":
        case "SVPM36":
        case "SVPM36DD":
        case "SVPYFI12":
        case "SVPYFI12DD":
        case "SVPYFI24":
        case "SVPYFI24DD":
        case "SVPYFI36":
        case "SVPYFI36DD": 
            colourMatchString = number < 40 ? "redColour" : "greenColour";
            break;
        case "SUPAGARDMTB":
            colourMatchString = number < 20 ? "redColour" : "greenColour";
            break;
        case "SMART36":
        case "SMART37":
        case "TYREINS":
        case "TYREINS24":
        case "TYREINS36":
        case "TYREINS48":
        case "TYREINS60":
        case "TYREINS72":
            colourMatchString = number < 25 ? "redColour" : "greenColour";
            break;
    }

    return colourMatchString;
}

export const SummaryTableSitePerformance = (props) => {
    const { tableData, dataType, totalItems, totalItemsBrand, listType, totalSold, totalSoldBrand, totalSoldTrade, modalHandler, war12M, warTopUp, adminFee, fuelCharge, tradeFee } = props;

    const modalHandlerString = 
        dataType === "financeNew" ? "fnVehicle" : 
            dataType === "financeUsed" ? "fuVehicle" : 
                dataType === "productsNew" ? "pnVehicle" :
                    dataType === "productsUsed" ? "puVehicle" :
                        dataType === "warrantyNew" ? "wnVehicle" :
                            dataType === "warrantyUsed" ? "wuVehicle" :
                                dataType === "accNew" ? "anVehicle" :
                                    dataType === "accUsed" ? "auVehicle" : "";

    return (
        dataType === "financeNew" || dataType === "financeUsed" ? <>
            <div className="summaryTable finType">
                <table>
                    <tr>
                        <th><span>Finance Company</span></th>
                        <th><span>Cases</span></th>
                        <th><span>Volume</span></th>
                        <th><span>Commission</span></th>
                        <th><span>Fin Pen</span></th>
                    </tr>
                        {
                            tableData.map((company, i) => {
                                if (company.cases !== null) {
                                    return (
                                        <>
                                            <tr>
                                                <td><span>{company.companyName} <span onClick={() => modalHandler(modalHandlerString, company.companyInvAccount, "", "")} className='popupOpenBtn'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                            <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                        </svg>
                                                    </span></span></td>
                                                <td><span>{company.cases}</span></td>
                                                <td><span>{formatters.CurrencyThousandSeparatorWithoutZero(company.volume)}</span></td>
                                                <td><span>{formatters.CurrencyThousandSeparatorWithoutZero(company.commission)}</span></td>
                                                <td><span>{Math.round((company.cases*100)/totalItems) + "%"}</span></td>
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                </table>
            </div>
        </> : 
        dataType === "productsNew" || dataType === "productsUsed" ? <>
            <div className="summaryTable finType lastDigit">
                <table>
                    <tr>
                        <th><span>Product Type</span></th>
                        <th><span>Units</span></th>
                        <th><span>Profit</span></th>
                        <th><span>Prior Year</span></th>
                        <th><span>Pen</span></th>
                        <th><span>{listType === "new" ? "Brand" : "Grp"} Pen</span></th>
                    </tr>
                        {
                            tableData.map((product, i) => {
                                if (product.cases !== null) {
                                    return (
                                        <>
                                            <tr>
                                                <td><span>
                                                    {product.description}
                                                    {
                                                        product.description !== "Supagard (MTB)" ? <><span onClick={() => modalHandler(modalHandlerString, product.optionCode, "", "")} className='popupOpenBtn'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                            <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                        </svg>
                                                    </span></> : <></>
                                                    }
                                                    
                                                </span></td>
                                                <td><span>{product.units}</span></td>
                                                <td><span>{formatters.CurrencyThousandSeparatorWithoutZero(product.profit)}</span></td>
                                                <td><span>{product.priorYear === null ? 0 : product.priorYear}</span></td>
                                                <td className={colorMatch(product.optionCode, Math.round((product.units*100)/totalSold))}><span>{Math.round((product.units*100)/totalSold) + "%"}</span></td>
                                                <td className='blackColour'><span>{Math.round((product.unitsBrand*100)/totalSoldBrand) + "%"}</span></td>
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                </table>
            </div>
        </> :
        dataType === "warrantyNew" || dataType === "warrantyUsed" ? <>
            <div className="summaryTable finType lastDigit">
                <table>
                    <tr>
                        <th><span>Warranty Type</span></th>
                        <th><span>Units</span></th>
                        <th><span>Profit</span></th>
                        <th><span>Prior Year</span></th>
                        <th><span>Pen %</span></th>
                        <th><span>Group Pen %</span></th>
                    </tr>
                        {
                            tableData.map((product, i) => {
                                if (product.cases !== null) {
                                    return (
                                        <>
                                            <tr>
                                                <td><span>{product.description}<span onClick={() => modalHandler(product.optionCode == "war12M" || product.optionCode == "warTopUp" ? "wuVehicleList" :  modalHandlerString, product.optionCode == "war12M" ? war12M : product.optionCode == "warTopUp" ? warTopUp : product.optionCode, "", "" )} className='popupOpenBtn'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                            <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                        </svg>
                                                    </span></span></td>
                                                <td><span>{product.units}</span></td>
                                                <td><span>{formatters.CurrencyThousandSeparatorWithoutZero(product.profit)}</span></td>
                                                <td><span>{product.priorYear === null ? 0 : product.priorYear}</span></td>
                                                <td className={product.description === "Chorley Care Essentials" || product.description === "NIC 12 Month" || product.description === "Warranty Top-Up" ? "blackColour" : Math.round((product.units*100)/totalSold) > Math.round((product.unitsBrand*100)/totalSoldBrand) ? "greenColour" : "redColour"}><span>{Math.round((product.units*100)/totalSold) + "%"}</span></td>
                                                <td className='blackColour'><span>{Math.round((product.unitsBrand*100)/totalSoldBrand) + "%"}</span></td>
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                </table>
            </div>
        </> : 
        dataType === "accNew" || dataType === "accUsed" ? <>
            <div className="summaryTable finType lastDigit">
                <table>
                    <tr>
                        <th><span>Product</span></th>
                        <th><span>Total Incorrect</span></th>
                        <th><span>Invoice</span></th>
                        <th><span>Cost</span></th>
                        {/* <th><span>Revenue</span></th> */}
                        <th><span>Both</span></th>
                        <th><span>Pen %</span></th>
                        {/* <th><span>Brand Pen</span></th> */}

                    </tr>
                        {
                            tableData.map((acc, i) => {
                                if (acc.totalVolume !== null) {
                                    return (
                                        <>
                                            <tr>
                                                <td><span>{acc.description}<span onClick={() => modalHandler(modalHandlerString, acc.lookupStock, acc.description, acc.description == "Admin Fee (Trade)" ? "T" : "R")} className='popupOpenBtn'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                            <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                        </svg>
                                                    </span></span></td>
                                                {/* <td><span>{acc.description == "Admin Fee" ? adminFee + acc.totalVolume : acc.description == "Fuel / EV Charge" ? fuelCharge + acc.totalVolume : tradeFee + acc.totalVolume }</span></td> */}
                                                <td><span>{ acc.totalVolume }</span></td>
                                                <td><span>{acc.mInvoiced}</span></td>
                                                <td><span>{acc.mCosted}</span></td>
                                                <td className='bothSecAcc'><span>{acc.both}</span></td>
                                                {/* <td><span className={acc.description == "Fuel / EV Charge" || acc.description == "Admin Fee" || acc.description == "Admin Fee (Trade)" ? acc.profit < 0 ? "redValue" : "" : acc.profit < acc.qntyGrp ? "redValue" : "" }>{formatters.CurrencyThousandSeparatorWithoutZero(acc.profit)}</span></td> */}
                                                <td className={acc.description == "Admin Fee (Trade)" ? 
                                                                    Math.round(100 - (((acc.totalVolume)*100)/totalSoldTrade)) < 95 ? 
                                                                        "redColour" : "" 
                                                                : 
                                                                acc.description == "Admin Fee" ? 
                                                                    Math.round(100 - (((acc.totalVolume)*100)/totalSold)) < 95 ? "redColour" : "" : Math.round(100 - (((acc.totalVolume)*100)/totalSold)) < 95 ? "redColour" : ""}>
                                                                
                                                                <span>{acc.description == "Admin Fee (Trade)" ? Math.round(100 - (((acc.totalVolume)*100)/totalSoldTrade)) + "%" : acc.description == "Admin Fee" ? Math.round(100 - (((acc.totalVolume)*100)/totalSold)) + "%" : Math.round(100 - (((acc.totalVolume)*100)/totalSold)) + "%"}</span></td>
                                                {/* <td><span>{acc.description == "Fuel / EV Charge" || acc.description == "Admin Fee" || acc.description == "Admin Fee (Trade)" ? <>-</> : <>
                                                    {Math.round((acc.qntyGrp*100)/totalSoldBrand) + "%"}
                                                </>}</span></td> */}
                                            </tr>
                                        </>
                                    )
                                }
                            })
                        }
                </table>
            </div>
        </> : <></>
    );
}

//This comment refers to the 'Profit and Loss Detail' component.

export const DetailsCards = (props) => {
    const { up, value, label, profitVal, budgetLable } = props;
    return (
        <div className="containerPadding">
            <Grid container className="PnLDbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12}>
                    {label === 'Total Units' ? (
                        <>
                            <div className="PnLDtype3_Text" style={{ marginBottom: -5 }}>
                                {label}
                            </div>
                            <small style={{ fontSize: 10 }}>(excl. Fleet/Trade)</small>
                        </>
                    ) : (
                        <div className="PnLDtype3_Text p4">{label || 'N/A'}</div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <div className="PnLDtype1_Text p4">{value || 0}</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="PnLDtype3_Text p4">{budgetLable ? budgetLable : 'BUDGET'}</div>
                </Grid>

                <Grid item xs={12}>
                    <div
                        className="PnLDtype1_Text p4"
                        style={{
                            padding: 0,
                            color: up == undefined ? 'black' : up ? '#569101' : '#E51A54'
                        }}
                    >
                        <span>{profitVal || 0}</span>
                        {up !== undefined && (
                            <span style={{ verticalAlign: 'middle' }}>
                                {!up ? <ArrowDownwardIcon className="PnLDtype2_Text" /> : <ArrowUpwardIcon className="PnLDtype2_Text" />}
                            </span>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const DetailsCardsWithoutBudget = (props) => {
    const { value, label, up, showBudget } = props;
    return (
        <div className="containerPadding">
            <Grid container className="PnLDbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12}>
                    <div className="PnLDtype3_Text p4">{label || 'N/A'}</div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <div
                            className="PnLDtype1_Text p4"
                            style={{
                                color: showBudget ? (!up ? '#E51A54' : '#569101') : 'black'
                            }}
                        >
                            <span>{value || 0}</span>
                            <span style={{ verticalAlign: 'middle' }}>
                                {showBudget ? !up ? <ArrowDownwardIcon className="PnLDtype2_Text" /> : <ArrowUpwardIcon className="PnLDtype2_Text" /> : null}
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const ProfitLossDetailsCardsCustom = (props) => {
    let history = useHistory();
    const { data } = props;
    let onRedirectToNewUnits = (link, searchQuery) => {
        history.push({ pathname: link, search: searchQuery || '' });
    };
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="kpiCard" style={{position: "relative"}}>
            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        alignTtems: 'center',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div className="PnLDsecondaryHeading p4">{props.label}</div>
                    <div>
                        <BudgetSendBtn onClick={() => onRedirectToNewUnits(props.link, props.searchQuery)} />
                    </div>
                </div>
            </Grid>
            <Grid item xs={4}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft ">TOTAL LABOUR </div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.labour)}</div>
                </div>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft ">Actual DRR</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(props.actualDrr)}</div>
                </div>
            </Grid>
            <Grid item xs={4}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft">MTD Budget</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(props.mtdBudget)}</div>
                </div>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft">Budget DRR</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(props.drrBudget)}</div>
                </div>
            </Grid>
            <Grid item xs={3}>
                <div className="PanLDetailringProperty">
                    <CircularProgressbar
                        value={setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget)}
                        text={`${setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget)}%`}
                        styles={buildStyles({
                            textColor:
                            setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) < 50
                                    ? '#D92641'
                                    : setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) >= 50 &&
                                    setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) >= 75
                                            ? '#569101'
                                            : '#D92641',
                            pathColor:
                            setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) < 50
                                    ? '#D92641'
                                    : setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) >= 50 &&
                                    setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) < 75
                                        ? '#ff9f00'
                                        : setPercentageOverHundred(data.labour ? data.labour : data?.profit, props.mtdBudget) >= 75
                                            ? '#569101'
                                            : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <BasicTable data={props.table} customColumn={true} currentWorkingDay={props.currentWorkingDay} workingDays={props.workingDays} />
            </Grid>
            <Grid item xs={12}></Grid>
            <span style={{position: "absolute", fontSize: "12px", right: "15px", bottom: "35px"}}><strong>Current Working Day:</strong> day {props.currentWorkingDay}</span>
            <span style={{position: "absolute", fontSize: "12px", right: "15px", bottom: "15px"}}><strong>Working Days: </strong>{props.workingDays} days</span>
        </Grid>
    );
};

export const ProfitLossDetailsCards = (props) => {
    let history = useHistory();
    const { data } = props;
    let onRedirectToNewUnits = (link, searchQuery) => {
        history.push({ pathname: link, search: searchQuery || '' });
    };
    return (
        <Grid container justifyContent="space-around" alignItems="center" className="kpiCard">
            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        alignTtems: 'center',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div className="PnLDsecondaryHeading p4">{props.label}</div>
                    <div>
                        <BudgetSendBtn onClick={() => onRedirectToNewUnits(props.link, props.searchQuery)} />
                    </div>
                </div>
            </Grid>
            <Grid item xs={4}>
                {!data.labour ? (
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="PnLDtype3_Text alignLeft ">Total Profit</div>
                        <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.profit)}</div>
                    </div>
                ) : (
                    <div style={{ width: '100%', marginBottom: 10 }}>
                        <div className="PnLDtype3_Text alignLeft ">TOTAL LABOUR </div>
                        <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.labour)}</div>
                    </div>
                )}

                <div style={{ width: '100%' }}>
                    <div className="PnLDtype3_Text alignLeft ">Budget Variance</div>
                    <div className="PnLDtype1_Text  alignLeft" style={{ color: data?.variance >= 0 ? 'green' : 'red' }}>
                        {formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(data?.variance))}
                    </div>
                </div>
            </Grid>
            <Grid item xs={4}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <div className="PnLDtype3_Text alignLeft">Total Budget</div>
                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.budget)}</div>
                </div>

                <div style={{ width: '100%' }}>
                    {data.TotalProfitPreviousYear ? (
                        <>
                            <div className="PnLDtype3_Text alignLeft">Total Prior Year </div>
                            <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.TotalProfitPreviousYear)}</div>
                        </>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {data.ppu ? (
                                <>
                                    <div className="PnLDtype3_Text alignLeft">Total PPU</div>
                                    <div className="PnLDtype1_Text  alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data?.ppu)}</div>
                                </>
                            ) : (
                                <>
                                    <div className="PnLDtype3_Text alignLeft">&nbsp;</div>
                                    <div className="PnLDtype1_Text  alignLeft">&nbsp;</div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item xs={3}>
                <div className="PanLDetailringProperty">
                    <CircularProgressbar
                        value={setPercentage(data.labour ? data.labour : data?.profit, data?.budget)}
                        text={`${setPercentage(data.labour ? data.labour : data?.profit, data?.budget)}%`}
                        styles={buildStyles({
                            textColor:
                                setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 50
                                    ? '#D92641'
                                    : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 50 &&
                                        setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 75
                                            ? '#569101'
                                            : '#D92641',
                            pathColor:
                                setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 50
                                    ? '#D92641'
                                    : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 50 &&
                                        setPercentage(data.labour ? data.labour : data?.profit, data?.budget) < 75
                                        ? '#ff9f00'
                                        : setPercentage(data.labour ? data.labour : data?.profit, data?.budget) >= 75
                                            ? '#569101'
                                            : '#D92641',
                            textSize: '27px'
                        })}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <BasicTable data={props.table} />
            </Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    );
};
//This comment refers to the 'Used Vehicle Stock and Used Stock Valuation' components.

export const UsedStocksCard = (props) => {
    const { CurrentStockValue, AverageStockValue, PreviousYearAverage, showStock, showOverage, setState } = props;

    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }

        setState((st) => ({
            ...st,
            groupIds: [branchID]
        }));
        let query = `ID=${branchID}`;
        props.history.push({
            pathname: link,
            search: query
        });
    };

    return (
        <div>
            <Grid container className="USVUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="USVsecondaryHeading alignLeft">{props.branch}</div>
                                </Grid>
                                <Grid item>
                                    <div style={{ width: '100%' }}>
                                        <div className="USVtype3_Text">Current Stock Value</div>
                                        <div className="USVtype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{ width: '100%', marginBottom: 5, display: 'flex' }}>
                                        <div className="USVtype3_Text">Average Stock Value</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                                        </div>
                                    </div>

                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div className="USVtype3_Text">Previous Year Average</div>
                                        &nbsp;&nbsp;&nbsp;
                                        <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container justifyContent="flex-end">
                                {showStock && (
                                    <Grid item style={{ width: '90%' }}>
                                        <SmallLinkedButton
                                            style={{ marginBottom: 5, width: '100%' }}
                                            active={props.active}
                                            onClick={() => handleRedirect(props.stockLink, props.branchID)}
                                        >
                                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}

                                {showOverage && (
                                    <Grid item style={{ width: '90%' }}>
                                        <SmallLinkedButton
                                            style={{ width: '100%' }}
                                            onClick={() => handleRedirect(props.priorityLink, props.branchID)}
                                        >
                                            OVERAGE &nbsp; &nbsp;
                                            <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const UsedStocksCardMobileView = (props) => {
    const { CurrentStockValue, AverageStockValue, PreviousYearAverage, showStock, showOverage, setState } = props;

    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        setState((st) => ({
            ...st,
            groupIds: [branchID]
        }));
        let query = `ID=${branchID}`;
        props.history.push({
            pathname: link,
            search: query
        });
    };

    return (
        <div>
            <Grid container className="USVUnitKipTwo_card" spacing={0}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="USVsecondaryHeading alignLeft">{props.branch}</div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid container justifyContent="flex-end">
                                {showStock && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton style={{ width: '100%' }} active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                            STOCK LIST
                                        </SmallLinkedButton>
                                    </Grid>
                                )}

                                {showOverage && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton style={{ width: '100%' }} onClick={() => handleRedirect(props.priorityLink, props.branchID)}>OVERAGE</SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <div style={{ width: '100%', marginTop: 0 }}>
                                <div className="USVtype3_Text">Current Stock Value</div>
                                <div className="USVtype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(CurrentStockValue)}</div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', marginTop: 5 }}>
                                <div>
                                    <div style={{ fontSize: 10 }}>Average Stock Value</div>
                                    <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(AverageStockValue)}
                                    </div>
                                </div>

                                <div style={{ marginLeft: 8 }}>
                                    <div style={{ fontSize: 10 }}>Previous Year Average</div>
                                    <div className="USVtype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(PreviousYearAverage)}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{
                                background: '#569101',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                marginTop: 10
                            }}
                        >
                            <div>
                                <div style={{ textAlign: 'center', fontWeight: 600 }}>{props.Units}</div>
                                <div style={{ fontWeight: 600 }}>UNITS</div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export const UsedStocksCardTWO = (props) => {
    const [WindowWidths] = useWindowSize();
    const { list } = props;
    return (
        <>
            {list.length > 0 ? (
                <Grid container justifyContent="space-evenly" alignItems="center" className="USVUnitKipThree_card" spacing={1}>
                    {list.map((m, index) => {
                        return (
                            <Grid item style={{ padding: '10px 3px' }} key={index}>
                                <Grid container direction="column" spacing={WindowWidths >= 600 ? 1 : 0} alignItems="center">
                                    <Grid item className="USVtype3_Text">
                                        {m.valuationDate1}
                                    </Grid>
                                    <Grid item className="USVtype1_Text">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue1)}
                                    </Grid>

                                    {list.length - 1 == index ? (
                                        <Grid item className="kpifonts">
                                            &nbsp;
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            className="USVtype3_Text"
                                            style={{
                                                fontWeight: 'bold',
                                                color: m.getCalculatedvalue >= 0 ? '#569101' : '#E51A54'
                                            }}
                                        >
                                            {parseFloat(m.getCalculatedvalue).toFixed(2)}%
                                            {m.getCalculatedvalue >= 0 ? (
                                                <ArrowUpwardIcon className="USVtype3_Text" />
                                            ) : (
                                                <ArrowDownwardIcon className="USVtype3_Text" />
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container justifyContent="space-evenly" alignItems="center" className="USVUnitKipThree_card">
                    <Grid item>
                        <div>
                            <div className="panLlabel mb10 mt10">No Record Found</div>
                        </div>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

//This comment refers to the 'Aged Vehicle Stock' component.

export const AgedStockCardMobileView = (props) => {
    const { data, showOverage, showStock } = props;
    let stockTurnColor = data.stockTurn <= 5 ? 'red' : data.stockTurn < 10 ? '#FFBF00' : '#569101';
    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        props.history.push({
            pathname: link,
            branchID: branchID
        });
    };

    const renderGridItem = (title, value) => (
        <Grid item>
            <Grid container spacing={0} direction="column" alignItems="center">
                <Grid item className="AVStype4_Text">
                    {title}
                </Grid>
                <Grid item className="AVStype1_Text">{`${value}`}</Grid>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid container className="AVSUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <div className="AVStype2_Text">{data.branchShortName}</div>
                            <div className="AVStype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container alignContent="flex-end">
                                {showStock && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton style={{ width: '100%' }} active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                            STOCK LIST
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                                {showOverage && (
                                    <Grid item xs={6} style={{ padding: '0px 6px' }}>
                                        <SmallLinkedButton style={{ width: '100%' }} onClick={() => handleRedirect(props.priorityLink, props.branchID)}>OVERAGE</SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ marginTop: '13px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total CAP Clean
                                        </Grid>
                                        <Grid item className="AVStype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total Variance&nbsp;&nbsp;
                                        </Grid>
                                        <Grid
                                            item
                                            className="AVStype3_Text"
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                color: data.totalCapClean - data.total > 0 ? '#569101' : '#E51A54'
                                            }}
                                        >
                                            <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean - data.total)}</span>
                                            <span style={{ verticalAlign: 'middle' }}>
                                                {data.totalCapClean - data.total < 0 ? (
                                                    <ArrowDownwardIcon className="AVStype3_Text" />
                                                ) : (
                                                    <ArrowUpwardIcon className="type3_Text" />
                                                )}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} direction="column">
                                <Grid item style={{ padding: 0 }}>
                                    <div style={{ fontSize: 9, textAlign: 'center' }}>Avg DIS</div>
                                    <div div style={{ textAlign: 'center', fontSize: 15 }}>
                                        {data.avgDis}
                                    </div>
                                </Grid>
                                <Grid item style={{ padding: 5 }}>
                                    <div style={{ borderBottom: '1px solid' }}></div>
                                </Grid>
                                <Grid item style={{ padding: 0 }}>
                                    <div style={{ fontSize: 9, textAlign: 'center' }}>Stock Turn</div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: stockTurnColor,
                                            fontSize: 15
                                        }}
                                    >
                                        {data.stockTurn}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{
                                background: '#569101',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                marginRight: 5,
                                padding: 6
                            }}
                        >
                            <div>
                                <div style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>{props.units}</div>
                                <div style={{ fontSize: 15, fontWeight: 600 }}>UNITS</div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Grid container spacing={1} justifyContent="space-evenly">
                        {props.list.map((m) => {
                            if (m.branchID === props.branchID) {
                                return (
                                    <>
                                        {renderGridItem('0 - 30 DAYS', m.totalCount0to30)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('31 - 60 DAYS', m.totalCount31to60)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('61 - 90 DAYS', m.totalCount61to90)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('91 - 120 DAYS', m.totalCount91to120)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('121 - 150 DAYS', m.totalCount121to150)}
                                        <Grid item style={{ borderRight: '1px solid', padding: 0 }}></Grid>
                                        {renderGridItem('150+ DAYS', m.totalCount150Plus)}
                                    </>
                                );
                            }
                            return null;
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const AgedStockCard = (props) => {
    const { data, showOverage, showStock } = props;
    let stockTurnColor = data.stockTurn <= 5 ? 'red' : data.stockTurn < 10 ? '#FFBF00' : '#569101';
    const handleRedirect = (link, branchID) => {
        let array = [];
        if (branchID) {
            array.push(branchID);
        }
        let query = `ID=${branchID}`;
        props.history.push({
            pathname: link,
            search: query
        });
    };

    return (
        <>
            <Grid container className="AVSUnitKipTwo_card">
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <div className="AVStype2_Text">{data.branch}</div>
                            <div className="AVStype1_Text alignLeft">{formatters.CurrencyThousandSeparatorWithoutZero(data.total)}</div>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" alignContent="flex-end" spacing={1}>
                                {showStock && (
                                    <Grid item style={{ width: '90%' }}>
                                        <SmallLinkedButton active={props.active} onClick={() => handleRedirect(props.stockLink, props.branchID)}>
                                            STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                                {showOverage && (
                                    <Grid item style={{ width: '90%' }}>
                                        <SmallLinkedButton onClick={() => handleRedirect(props.priorityLink, props.branchID)}>
                                            OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 10 }} />
                                        </SmallLinkedButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between" style={{ marginTop: '8px' }}>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total CAP Clean
                                        </Grid>
                                        <Grid item className="AVStype3_Text" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item className="AVStype3_Text">
                                            Total Variance&nbsp;&nbsp;
                                        </Grid>
                                        <Grid
                                            item
                                            className="AVStype3_Text"
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                color: data.totalCapClean - data.total > 0 ? '#569101' : '#E51A54'
                                            }}
                                        >
                                            <span>{formatters.CurrencyThousandSeparatorWithoutZero(data.totalCapClean - data.total)}</span>
                                            <span style={{ verticalAlign: 'middle' }}>
                                                {data.totalCapClean - data.total < 0 ? (
                                                    <ArrowDownwardIcon className="AVStype3_Text" />
                                                ) : (
                                                    <ArrowUpwardIcon className="type3_Text" />
                                                )}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <div className="AVStype3_Text">Avg DIS</div>
                                    <div className="AVStype1_Text" style={{ textAlign: 'center' }}>
                                        {data.avgDis}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ borderRight: '1px solid', height: '100%' }}></div>
                                </Grid>
                                <Grid item>
                                    <div className="AVStype3_Text">Stock Turn</div>
                                    <div
                                        className="AVStype1_Text"
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: stockTurnColor
                                        }}
                                    >
                                        {data.stockTurn}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const AgedStocksCardTWO = (props) => {
    const { list } = props;
    return (
        <div className="kpiCard">
            <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                {list
                    .filter((m) => m.branchID === props.branchID)
                    .map((m) => (
                        <Grid item key={m.valuationDays1}>
                            <div>
                                <div className="panLlabel mb10">{m.valuationDays1}</div>
                                <div className="panLvalue mb10">{`${m.totalCount1} (${m.percent1}%)`}</div>
                                <div className="budgetCard2BudgetNum" style={{ textAlign: 'center', fontSize: 18 }}>
                                    {formatters.CurrencyThousandSeparatorWithoutZero(m.stockValue1)}
                                </div>
                            </div>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
};

export const AgedStocksDayRangeCard = (props) => {
    const [WindowWidths] = useWindowSize();

    const renderGridItem = (title, count, percentage, stockValue) => (
        <Grid item xs={4} sm={2} style={{ padding: '15px 3px' }}>
            <Grid container spacing={WindowWidths >= 600 ? 1 : 0} direction="column" alignItems="center">
                <Grid item className="AVStype3_Text">
                    {title}
                </Grid>
                <Grid item className="AVStype1_Text">{`${count} (${percentage}%)`}</Grid>
                <Grid item className="AVStype3_Text">
                    {formatters.CurrencyThousandSeparatorWithoutZero(stockValue)}
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid container justifyContent={'space-evenly'} alignItems="center" className="AVSUnitKipThree_card">
            {props.list.map((m, i) => {
                if (m.branchID === props.branchID) {
                    return (
                        <React.Fragment key={`${i}_AVS_DAYS`}>
                            {renderGridItem('0 - 30 DAYS', m.totalCount0to30, m.totalPerc0to30, m.stockValue0to30)}
                            {renderGridItem('31 - 60 DAYS', m.totalCount31to60, m.totalPerc31to60, m.stockValue31to60)}
                            {renderGridItem('61 - 90 DAYS', m.totalCount61to90, m.totalPerc61to90, m.stockValue61to90)}
                            {renderGridItem('91 - 120 DAYS', m.totalCount91to120, m.totalPerc91to120, m.stockValue91to120)}
                            {renderGridItem('121 - 150 DAYS', m.totalCount121to150, m.totalPerc121to150, m.stockValue121to150)}
                            {renderGridItem('150+ DAYS', m.totalCount150Plus, m.totalPerc150Plus, m.stockValue150Plus)}
                        </React.Fragment>
                    );
                }
                return null;
            })}
        </Grid>
    );
};

//This comment refers to the 'Sales Reporting' component.

export const SalesCard = (props) => {
    const { value, label, subLabelOne, subLabelTwo } = props;
    return (
        <div className="containerPadding">
            <Grid container className="SRbranchOverViewKip_card" alignItems="center" alignContent="center">
                <Grid item xs={12} className="SRtype3_Text p4">
                    {label || 'N/A'}
                </Grid>
                <Grid item xs={12} className="SRtype1_Text p4">
                    {formatters.ThousandSeparatorWithoutZero(value) || 0}
                </Grid>

                <Grid item xs={12} style={{ display: 'flex ' }}>
                    <div style={{ width: '100%', borderRight: '1px solid' }}>
                        <div className="SRtype3_Text p4">{subLabelOne}</div>
                        <div className="SRtype3_Text p4" style={{ color: '#569101' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(props.newProfit)}
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="SRtype3_Text p4">{subLabelTwo}</div>
                        <div className="SRtype3_Text p4" style={{ color: '#569101' }}>
                            {formatters.CurrencyThousandSeparatorWithoutZero(props.newPpu)}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
