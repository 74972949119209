import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import formatters from '../Grid/inputFormatter';
import { useWindowSize } from '../Controls/ScreenResolution';

const useStyles = makeStyles({
    table: {
        minWidth: '100%'
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 6,
        fontWeight: 'bold',
        fontFamily: `Montserrat`,
        fontSize: 10
    },
    body: {
        fontSize: 10,
        fontFamily: `Montserrat`,
        padding: 10,
        fontWeight: 600
    }
}))(TableCell);

export const BasicTable = (props) => {
    const [widths] = useWindowSize();
    const classes = useStyles();

    const widthPercent = props.customColumn ? "16%" : "22%";
    const mainWidthPercent = props.customColumn ? "20%" : "30%";

    return (
        <TableContainer style={{ minHeight: widths >= 600 ? 310 : 400, maxHeight: widths >= 600 ? 310 : 400 }}>
            <Table className={classes.table} aria-label="sticky table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell width={mainWidthPercent}></StyledTableCell>
                        <StyledTableCell width={widthPercent} align="right">
                            ACTUAL
                        </StyledTableCell>
                        <StyledTableCell width={widthPercent} align="right">
                            BUDGET
                        </StyledTableCell>
                        {
                            props.customColumn ? <>
                                <StyledTableCell width={widthPercent} align="right">
                                    BUDGET MTD
                                </StyledTableCell>
                            </>
                            : <></>
                        }
                        <StyledTableCell width={widthPercent} align="right">
                            {
                                props.customColumn ? "VARIANCE" : "PRIOR YEAR"
                            }
                        </StyledTableCell>
                        {
                            props.customColumn ? <>
                                <StyledTableCell width={widthPercent} align="right">
                                    OVERALL RECOVERY
                                </StyledTableCell>
                            </>
                            : <></>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data?.map((row) => {
                        let index = row.description.toLowerCase().search('volume');
                        let budgetMtd = props.customColumn ? Math.round((row.budget/props.workingDays)*props.currentWorkingDay) : 0;
                        let prDiff = props.customColumn ? row.currentValue - budgetMtd : row.currentValue - row.lastYear;
                        
                        return (
                            <TableRow key={row.description}>
                                <StyledTableCell component="th" scope="row">
                                    {row.description}
                                </StyledTableCell>
                                {row.currentValue ? (
                                    <StyledTableCell align="right">
                                        {index === -1
                                            ? formatters.CurrencyThousandSeparatorWithoutZero(row.currentValue)
                                            : formatters.ThousandSeparatorWithoutZero(row.currentValue)}
                                    </StyledTableCell>
                                ) : (
                                    <StyledTableCell align="right">-</StyledTableCell>
                                )}
                                {row.budget ? (
                                    <StyledTableCell align="right">
                                        {index === -1
                                            ? formatters.CurrencyThousandSeparatorWithoutZero(row.budget)
                                            : formatters.ThousandSeparatorWithoutZero(row.budget)}
                                    </StyledTableCell>
                                ) : (
                                    <StyledTableCell align="right">-</StyledTableCell>
                                )}
                                {props.customColumn ? (
                                    <StyledTableCell align="right">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(budgetMtd)}
                                    </StyledTableCell>
                                )  : <></>}
                                {!isNaN(row.lastYear) ? (
                                    <StyledTableCell align="right" style={{ color: prDiff >= 0 ? '#569101' : 'red' }}>
                                        <b>
                                            {props.customColumn ? formatters.CurrencyThousandSeparatorWithoutZero(prDiff) : index === -1
                                                ? formatters.CurrencyThousandSeparatorWithoutZero(row.lastYear)
                                                : formatters.ThousandSeparatorWithoutZero(row.lastYear)}
                                            &nbsp;
                                        </b>
                                        {prDiff <= 0 ? <ArrowDownwardIcon style={{ fontSize: 12 }} /> : <ArrowUpwardIcon style={{ fontSize: 12 }} />}
                                    </StyledTableCell>
                                ) : (
                                    <StyledTableCell align="right">-</StyledTableCell>
                                )}
                                {props.customColumn ? (
                                    <StyledTableCell align="right">
                                        {formatters.CurrencyThousandSeparatorWithoutZero(row.currentValue/row.totalHoursSold)}
                                    </StyledTableCell>
                                )  : <></>}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
