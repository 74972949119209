import React, { useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { SemiCircleProgress } from 'react-semicircle-progressbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination]);

export const LineWithIcon = (props) => {
    let checker = false;
    let checkerGreen = false;

    const GreenCheck = () => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M6.3335 8.63642L8.11127 10.3334L11.6668 6.93945" stroke="#4F8000" strokeWidth={"2"} strokeLinecap='round' strokeLinejoin='round'/>
                    <ellipse cx="9" cy="8.63636" rx="8" ry="7.63636" stroke="#4F8000" strokeWidth={"2"}/>
                </svg>
            </>
        );
    }

    const RedCheck = () => {
        return (
            <>
               <svg version="1.1" id="Layer_1" width={"18px"} height={"18px"} x="0px" y="0px"
	                viewBox="0 0 332.19 318.79">
                    <g>
                        <g>
                            <path style={{fill: "#BF2B26"}} d="M165.42,311.61c-58.73-2.34-105.85-26.2-137.48-76.9C-5.54,181.07,0.61,109.8,42.05,62.06
                                c35.34-40.71,80.56-59.84,133.9-56.4c59.45,3.83,104.79,32.34,133.38,85.21c12.85,23.75,17.26,49.58,15.88,76.7
                                c-3.3,64.87-51.91,120.5-113.71,137.25c-7.21,1.95-14.52,3.76-21.9,4.83C181.72,310.78,173.69,310.98,165.42,311.61z
                                M290.76,166.2c0.16-39.46-13.86-71.39-37.4-92.46C224.5,47.9,190.74,36.85,152.17,40.79c-30.66,3.13-56.97,15.66-78.29,37.86
                                c-39.3,40.93-43.44,102.08-10.33,146.89c34.55,46.76,98.78,64.38,152.55,40.93C262.99,246.01,288.18,209.89,290.76,166.2z"/>
                            <path style={{fill: "#BF2B26"}} d="M153.28,74.41c8.24,0,16.16,0,24.8,0c-1.44,36.28-2.88,72.26-4.34,108.96c-5.07,0-9.85,0.25-14.57-0.2
                                c-1.01-0.1-2.45-2.72-2.54-4.25c-0.54-8.63-0.73-17.28-1.03-25.92c-0.91-25.6-1.83-51.2-2.73-76.79
                                C152.86,75.72,153.08,75.25,153.28,74.41z"/>
                            <path style={{fill: "#BF2B26"}} d="M165.39,206.78c8.36-0.03,14.58,6.13,14.57,14.41c-0.01,8.06-6.58,14.57-14.64,14.51
                                c-7.99-0.06-14.81-6.78-14.77-14.57C150.58,213.14,157.13,206.81,165.39,206.78z"/>
                        </g>
                    </g>
                </svg>
            </>
        );
    }

    if (props.iconType === "checker" || props.iconType === "checkerIcon") {
        checker = true;

        if (props.value <= 0) {
            checkerGreen = true;
        }
    }

    return (
        <>
            <div className={props.iconType === "checker" || props.iconType === "checkerIcon" ? "stockBoxLineIcon checker" : props.iconType === "fullNoIcon" ? "stockBoxLineIcon fullNoIcon" : "stockBoxLineIcon"}>
                <div className="stockBoxlineLeft">
                    {
                        checker ? checkerGreen ?
                            <GreenCheck /> : <RedCheck /> : ""
                    }
                    <span className='stockBoxLineTitle'>{props.title}</span>
                </div>
                <div className={props.value === 0 ? "stockBoxLineRight grey" : checker ? checkerGreen ? "stockBoxLineRight greenLine" : "stockBoxLineRight redLine" : "stockBoxLineRight"}>
                    <span className='stockBoxLineValue'>
                            {props.iconType === "fullNoIcon" && props.funcValue !== undefined ? <>
                                <span className='fullNoIconFunc' onClick={() => props.func(props.funcType, props.funcValue)}>{props.value}</span> {props.valueTotal ? <>/ {props.valueTotal}</> : ""}
                            </> : <>
                                {props.value} {props.valueTotal ? <>/ {props.valueTotal}</> : ""}
                            </>}
                    </span>
                    {
                        props.valueCurrency !== "" ? <>
                            <span className='stockBoxLineCurrency'>({props.valueCurrency})</span>
                        </> : <></>
                    }
                    {
                        props.iconType === "link" || props.iconType === "checkerIcon"  ? <>
                            <svg style={{marginLeft: "7px", cursor: "pointer"}} onClick={() => props.func(props.funcType, props.funcValue)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path className={props.value === 0 ? "greyPath" : ""} d="M13.4415 6.62732H22M13.4415 11.4421H19.5547M13.4415 16.2569H17.1094M5.80564 6V18M5.80564 18L2 14.3317M5.80564 18L9.7566 14.3317" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </> : props.iconType === "checker" || props.iconType === "noIcon" ? <>
                            <span className='spacingLinkIcon'></span>
                        </> :
                        <></>
                    }
                </div>
            </div>  
            
        </>
    )
}

export const UsedStockReportTotalStock = (props) => {
    let {allData} = props;
    const [mobileHeader, setMobileHeader] = useState("daysInStock");
    const [WindowWidths] = useWindowSize();

    let dueInCalc = allData.due7 + allData.dueEom + allData.duePlusEom;
    let dueInPercentage = (dueInCalc*100)/allData.limits[0].overallLimit;
    let reservedPercentage = (allData.reserved*100)/allData.limits[0].overallLimit;

    const SmallGraphBars = (props) => {
        return (
            <>
                <div className={props.valueDesc === "Reserved" ? "stockBoxDaysStockValueWrp customspacing" : "stockBoxDaysStockValueWrp"}>
                    <div className="stockBoxDaysStockValueLeft">
                        <span className="stockBoxDaysStockValueDesc">{props.valueDesc}</span>
                        <span className="stockBoxDaysStockValueProgress" style={{ width: ((props.progress*60)/100)+"%", maxWidth: "60%", background: props.colour }}></span>
                    </div>
                    <div className="stockBoxDaysStockValueRight">
                        <span className="stockBoxDaysStockValue"><span className='stockBoxValue' onClick={() => props.func(props.funcType, props.funcValue)}>{props.value}</span> <span>({props.progress+"%"})</span></span>
                    </div>
                </div>
            </>
        )
    }

    const DaysInStock = (props) => {
        return (
            <>
                <SmallGraphBars
                    valueDesc={"0-30"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis30 * 100) / props.data.vehicleCount) : 0}
                    colour={"#4F8000"}
                    value={props.data.dis30}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={2}
                />
                <SmallGraphBars
                    valueDesc={"31-60"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis60 * 100) / props.data.vehicleCount) : 0}
                    colour={"#6BC167"}
                    value={props.data.dis60}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={3}
                />
                <SmallGraphBars
                    valueDesc={"61-90"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis90 * 100) / props.data.vehicleCount) : 0}
                    colour={"#FFC400"}
                    value={props.data.dis90}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={4}
                />
                <SmallGraphBars
                    valueDesc={"90+"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis90Plus * 100) / props.data.vehicleCount) : 0}
                    colour={"#FF4436"}
                    value={props.data.dis90Plus}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={5}
                />
                <SmallGraphBars
                    valueDesc={"Reserved"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.reserved * 100) / props.data.vehicleCount) : 0}
                    colour={"#537ADB"}
                    value={props.data.reserved}
                    func={props.handleClickFilter}
                    funcType={"vehicleStatus"}
                    funcValue={"R"}
                />
            </>
        );
    }

    const StockProfile = (props) => {
        return (
            <>
                <LineWithIcon
                    title={"Retail"}
                    value={props.data.retailQnty}
                    valueTotal={props.data.limits[0].overallLimit}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.retailValue)}
                    iconType={"fullNoIcon"}
                />
                <LineWithIcon
                    title={"Pre-Reg"}
                    value={props.data.preRegQnty}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.preRegValue)}
                    iconType={"fullNoIcon"}
                />
                <LineWithIcon
                    title={"Demo"}
                    value={props.data.demoQnty}
                    valueTotal={props.data.limits[0].demoStockLimit}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.demoValue)}
                    iconType={"fullNoIcon"}
                />
                <LineWithIcon
                    title={"CBS"}
                    value={props.data.cbsQnty}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.cbsValue)}
                    iconType={"fullNoIcon"}
                />
                <LineWithIcon
                    title={"CMV"}
                    value={props.data.cmvQnty}
                    valueTotal={props.data.limits[0].cMVLimit}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.cmvValue)}
                    iconType={"fullNoIcon"}
                />
                <LineWithIcon
                    title={"Courtesy Car"}
                    value={props.data.courtesyQnty}
                    valueTotal={props.data.limits[0].courtesyCarLimit}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.courtesyValue)}
                    iconType={"fullNoIcon"}
                />
                <LineWithIcon
                    title={"LT Hire"}
                    value={props.data.ltHireQnty}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.ltHireValue)}
                    iconType={"fullNoIcon"}
                />
                <LineWithIcon
                    title={"Trade"}
                    value={props.data.tradeQnty}
                    valueCurrency={formatters.MillionWithMWithoutZero(props.data.tradeValue)}
                    iconType={"fullNoIcon"}
                />
            </>
        );
    }

    const DueIn = (props) => {
        return (
            <>
                {/* <SmallGraphBars
                    valueDesc={"Stock"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.vehicleCount * 100) / props.data.vehicleCount) : 0}
                    colour={"#537ADB"}
                    value={props.data.vehicleCount}
                    func={props.handleClickFilter}
                    funcType={"stockProfile"}
                    funcValue={11}
                /> */}
                <SmallGraphBars
                    valueDesc={"< 7 Days"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.due7 * 100) / props.data.vehicleCount) : 0}
                    colour={"#537ADB"}
                    value={props.data.due7}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={6}
                />
                <SmallGraphBars
                    valueDesc={"EoM"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dueEom * 100) / props.data.vehicleCount) : 0}
                    colour={"#537ADB"}
                    value={props.data.dueEom}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={7}
                />
                <SmallGraphBars
                    valueDesc={"Later"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.duePlusEom * 100) / props.data.vehicleCount) : 0}
                    colour={"#537ADB"}
                    value={props.data.duePlusEom}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={8}
                />
            </>
        );
    }

    return (
        <>
            <div className="stockBoxWrp flexbox">
                <div className="stockBoxLeft">
                    <div className="stockBoxTop">
                        <div className="stockBoxTopLeft">
                            <span className="stockBoxMainLine">
                                {allData.vehicleCount}<span></span>
                                
                            </span>
                            <span className="stockBoxMainLineText">Total Stock</span>
                            <span className='stockBoxSmallLine'>(excl. Trade)</span>
                        </div>
                        <div className="stockBoxTopRight">
                            <span className="stockBoxTopRightValue">Avg DIS <span>{allData.avgDis}</span></span>
                            <span className="stockBoxTopRightValue">Stock Turn <span>{allData.stockTurn}</span></span>
                        </div>
                    </div>

                    {/* <div className="stockBoxTypesBarWrp">
                        <div className="stockBoxTypesBar">
                            <span style={{width: "60%", background: "#5F83DD", height: "100%", display: "block"}}></span>
                            <span style={{width: reservedPercentage+"%", background: "#147E00", height: "100%", display: "block"}}></span>
                            <span style={{width: dueInPercentage+"%", background: "#41C5C3", height: "100%", display: "block"}}></span>
                        </div>
                        <div className="stockBoxTypesInr">
                            <span className="stockBoxTypes">
                                <span className="stockBoxTypesColour" style={{background: "#5F83DD"}}></span>
                                <span className="stockBoxTypeValue" style={{color: "#5F83DD"}}>{allData.vehicleCount-allData.reserved-dueInCalc}</span>
                                <span>Stock</span>
                            </span>
                            <span className="stockBoxTypes">
                                <span className="stockBoxTypesColour" style={{background: "#147E00"}}></span>
                                <span className="stockBoxTypeValue" style={{color: "#147E00"}}>{allData.reserved}</span>
                                <span>Res</span>
                            </span>
                            <span className="stockBoxTypes">
                                <span className="stockBoxTypesColour" style={{background: "#41C5C3"}}></span>
                                <span className="stockBoxTypeValue" style={{color: "#41C5C3"}}>{dueInCalc}</span>
                                <span>Due In</span>
                            </span>
                        </div>
                    </div> */}

                    {
                        WindowWidths < 1199 ? <>
                            <div className='stockBoxMobileOptions'>
                                <div className='stockBoxMobileOptionsHeader'>
                                    <span className={mobileHeader === "daysInStock" ? "active" : ""} onClick={() => setMobileHeader("daysInStock")}>DAYS IN STOCK</span>
                                    <span className={mobileHeader === "stockProfile" ? "active" : ""} onClick={() => setMobileHeader("stockProfile")}>STOCK PROFILE</span>
                                    <span className={mobileHeader === "dueIn" ? "active" : ""} onClick={() => setMobileHeader("dueIn")}>PART-EX DUE IN</span>
                                </div>
                                <div className="stockBoxMobileOptionsInr">
                                    <div className={mobileHeader === "daysInStock" ? "stockMobileOption active" : "stockMobileOption" }>
                                        <DaysInStock data={allData} />
                                    </div>
                                    <div className={mobileHeader === "stockProfile" ? "stockMobileOption active" : "stockMobileOption" }>
                                        <StockProfile data={allData} />
                                    </div>
                                    <div className={mobileHeader === "dueIn" ? "stockMobileOption active" : "stockMobileOption" }>
                                        <DueIn data={allData} />
                                    </div>  
                                </div>  
                            </div>
                        </> : 
                        <>
                            
                            <div className="stockBoxRightTop">
                                <StockProfile data={allData} handleClickFilter={props.handleClickFilter} />
                            </div>
                        </>
                    }
                </div>  
                {
                    WindowWidths > 1200 ? <>
                        <div className="stockBoxRight">
                            <div className="stockBoxDaysStock">
                                <span className="stockBoxItemTitle">DAYS IN STOCK</span>
                                <DaysInStock data={allData} handleClickFilter={props.handleClickFilter} />
                            </div>
                            <div className="stockBoxRightBottom">
                                <span className="stockBoxItemTitle">PART-EX DUE IN</span>
                                <DueIn data={allData} handleClickFilter={props.handleClickFilter} />
                            </div>
                        </div>
                    </> : <></>
                }
            </div>
        </>
    );
}

export const UsedStockReportToDo = (props) => {
    let {allData} = props;
    const [WindowWidths] = useWindowSize();
    var preregRetail = allData.preRegQnty+allData.retailQnty;
    var percentageStock = Math.round((allData.atPublished*100)/(preregRetail));

    return (
        <>
            <div className="stockBoxWrp flexbox">
                <div className='stockBoxLeft'>
                    <div className="stockBoxTop">
                        <div className="stockBoxTopLeft">
                            <span className='stockBoxLeftSubTitle'>ACTION LIST</span>
                                <span className='stockBoxLeftToDo'>
                                    <span className='stockToDoValue'>
                                        {
                                            allData.needAction
                                        } 
                                    </span>
                                    <span> vehicles need action</span>
                                </span>
                                
                        </div>
                        {/* {
                            WindowWidths < 991 ? <>
                                <div className="stockBoxTopRight">
                                    <span className="stockBoxTopRightValue">Avg DIS <span>{allData.avgDis}</span></span>
                                    <span className="stockBoxTopRightValue">Stock Turn <span>{allData.stockTurn}</span></span>
                                </div>    
                            </> : <></>
                         } */}
                    </div>

                    <LineWithIcon
                        title={"No Price"}
                        value={allData.noPrice}
                        valueCurrency={Math.round((allData.noPrice*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={5}
                    />
                    <LineWithIcon
                        title={"No Photos"}
                        value={allData.noPhotos}
                        valueCurrency={Math.round((allData.noPhotos*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={1}
                    />
                    <LineWithIcon
                        title={"No Att Grabber"}
                        value={allData.noAttGrabber}
                        valueCurrency={Math.round((allData.noAttGrabber*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={2}
                    />
                    <LineWithIcon
                        title={"To Prep"}
                        value={allData.toPrep}
                        valueCurrency={Math.round((allData.toPrep*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={3}
                    />
                    <LineWithIcon
                        title={"Price Change"}
                        value={allData.priceChange}
                        valueCurrency={Math.round((allData.priceChange*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={4}
                    />
                    <LineWithIcon
                        title={"No CAP Code"}
                        value={allData.noCapCode}
                        valueCurrency={Math.round((allData.noCapCode*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={6}
                    />
                    <LineWithIcon
                        title={"Low Perf. Rating"}
                        value={allData.lowLeads}
                        valueCurrency={Math.round((allData.lowLeads*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={7}
                    />
                    <LineWithIcon
                        title={"Reserved 3+ Day"}
                        value={allData.reserver10PlusDay}
                        valueCurrency={Math.round((allData.reserver10PlusDay*100)/(preregRetail))+"%"}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={8}
                    />
                </div>
                {
                    WindowWidths > 1200 ? <>
                        <div className="stockBoxRight">
                            <span className='stockBoxLeftSubTitle'>ADVERTISED STOCK</span>
                            <div className='halfCircleProgress'>         
                                <SemiCircleProgress
                                    percentage={percentageStock}
                                    size={{
                                        width: 200,
                                        height: 200,
                                    }}
                                    fontStyle={{
                                        display: "none"
                                    }}
                                    strokeWidth={8}
                                    strokeColor="#4A3AFF"
                                    hasBackground={true}
                                    bgStrokeColor="#E5EAFC"
                                />
                                <div className='halfCircleText'>
                                    <span className='halfCircleValue'>{percentageStock+"%"}</span>
                                    <span className='halfCircleSub'>Stock Advertised</span>
                                    <span className='halfCircleSmall'>{allData.atPublished}/{preregRetail}</span>
                                </div>
                            </div>
                            <div className="lineItemsWrp">
                                <LineWithIcon
                                    title={"Avg Retail Rating"}
                                    value={allData.avrRating}
                                    valueTotal={100}
                                    valueCurrency={""}
                                    iconType={"noIcon"}
                                />
                                <LineWithIcon
                                    title={"Total Advertised Margin"}
                                    value={formatters.MillionWithMWithoutZero(allData.totalAdvMargin)}
                                    valueCurrency={""}
                                    iconType={"link"}
                                    func={props.handleClickFilter}
                                    funcType={"listUpdate"}
                                    funcValue={27}
                                />
                                <LineWithIcon
                                    title={"Total Unadvertised Margin"}
                                    value={formatters.MillionWithMWithoutZero(allData.totalUnAdvMargin)}
                                    valueCurrency={""}
                                    iconType={"link"}
                                    func={props.handleClickFilter}
                                    funcType={"listUpdate"}
                                    funcValue={29}
                                />
                                <LineWithIcon
                                    title={"Total Reserved Margin"}
                                    value={formatters.MillionWithMWithoutZero(allData.totalReservedMargin)}
                                    valueCurrency={""}
                                    iconType={"link"}
                                    func={props.handleClickFilter}
                                    funcType={"listUpdate"}
                                    funcValue={30}
                                />
                                <LineWithIcon
                                    title={"Low Quality Ads"}
                                    value={allData.lowQualityAds}
                                    valueCurrency={Math.round((allData.lowQualityAds*100)/(allData.atPublished))+"%"}
                                    iconType={"noIcon"}
                                    // iconType={"link"}
                                    // func={props.handleClickFilter}
                                    // funcType={"stockProfile"}
                                    // funcValue={2}
                                />
                                {/* <LineWithIcon
                                    title={"AT Rejected Ads"}
                                    value={allData.atRejectedAds}
                                    valueCurrency={"2%"}
                                    iconType={"link"}
                                    func={props.handleClickFilter}
                                    funcType={"stockProfile"}
                                    funcValue={2}
                                /> */}
                            </div>
                        </div>
                    </> : <></>
                }
            </div>
        </>
    );
}

export const UsedStockCurrentValue = (props) => {
    let {allData} = props;
    const [WindowWidths] = useWindowSize();
    let capPercentage = Math.round(((allData.capValue*100/allData.retailTotal)));
    let atPercentage = Math.round(((allData.atValue*100/allData.retailTotal)));
    let budget = allData.limits[0].branchBudget;
    let budgetPercent = budget === 0 ? 0 : Math.round((allData.retailTotal*100)/budget);
    let capQnty = (allData.preRegQnty+allData.retailQnty)-allData.noCapCode;

    const GreenCheck = () => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M6.3335 8.63642L8.11127 10.3334L11.6668 6.93945" stroke="#4F8000" strokeWidth={"2"} strokeLinecap='round' strokeLinejoin='round'/>
                    <ellipse cx="9" cy="8.63636" rx="8" ry="7.63636" stroke="#4F8000" strokeWidth={"2"}/>
                </svg>
            </>
        );
    }

    const RedCheck = () => {
        return (
            <>
               <svg version="1.1" id="Layer_1" width={"18px"} height={"18px"} x="0px" y="0px"
	                viewBox="0 0 332.19 318.79">
                    <g>
                        <g>
                            <path style={{fill: "#BF2B26"}} d="M165.42,311.61c-58.73-2.34-105.85-26.2-137.48-76.9C-5.54,181.07,0.61,109.8,42.05,62.06
                                c35.34-40.71,80.56-59.84,133.9-56.4c59.45,3.83,104.79,32.34,133.38,85.21c12.85,23.75,17.26,49.58,15.88,76.7
                                c-3.3,64.87-51.91,120.5-113.71,137.25c-7.21,1.95-14.52,3.76-21.9,4.83C181.72,310.78,173.69,310.98,165.42,311.61z
                                M290.76,166.2c0.16-39.46-13.86-71.39-37.4-92.46C224.5,47.9,190.74,36.85,152.17,40.79c-30.66,3.13-56.97,15.66-78.29,37.86
                                c-39.3,40.93-43.44,102.08-10.33,146.89c34.55,46.76,98.78,64.38,152.55,40.93C262.99,246.01,288.18,209.89,290.76,166.2z"/>
                            <path style={{fill: "#BF2B26"}} d="M153.28,74.41c8.24,0,16.16,0,24.8,0c-1.44,36.28-2.88,72.26-4.34,108.96c-5.07,0-9.85,0.25-14.57-0.2
                                c-1.01-0.1-2.45-2.72-2.54-4.25c-0.54-8.63-0.73-17.28-1.03-25.92c-0.91-25.6-1.83-51.2-2.73-76.79
                                C152.86,75.72,153.08,75.25,153.28,74.41z"/>
                            <path style={{fill: "#BF2B26"}} d="M165.39,206.78c8.36-0.03,14.58,6.13,14.57,14.41c-0.01,8.06-6.58,14.57-14.64,14.51
                                c-7.99-0.06-14.81-6.78-14.77-14.57C150.58,213.14,157.13,206.81,165.39,206.78z"/>
                        </g>
                    </g>
                </svg>
            </>
        );
    }

    return (
        <div className="stockBoxWrp flexbox">
            <div className='stockBoxLeft'>
                <div className="stockBoxTop">
                    <div className="stockBoxTopLeft">
                        <span className='stockBoxLeftSubTitle blackText customSpacing'>Current Stock Value</span>
                        <span className='stockBoxLeftMainTitle'>{formatters.CurrencyThousandSeparatorWithoutZero(allData.retailTotal)}</span>
                        <span className='stockBoxLeftBudget'>Stock Budget <span className='stockBoxBudgetValue'>{formatters.MillionWithMWithoutZero(budget)} </span><span className={budgetPercent > 100 ? "stockBoxBudgetPercent redBudget" : "stockBoxBudgetPercent greenBudget"}>({budgetPercent+"%"})</span></span>
                        <span className='stockBoxRetail'><span>Retail & Pre-Reg Units</span> <span>{allData.preRegQnty+allData.retailQnty}/{allData.vehicleCount}</span></span>
                        <span className='stockBoxAvgSiv'><span>Average SIV</span><span>{formatters.CurrencyThousandSeparatorWithoutZero(Math.round(allData.retailTotal/(allData.preRegQnty+allData.retailQnty)))}</span></span>
                    </div>
                </div>
                <div className="currentStockBars">
                    <div className="currentStockBarWrp">
                        <span className='currentStockTitle'><span>AT Trade <span>({allData.atPublished}/{allData.preRegQnty+allData.retailQnty})</span></span><span className='currentStockValue'>{formatters.CurrencyThousandSeparatorWithoutZero(allData.atValue)}</span></span>
                        <span className='currentStockBar'><span className='currentStockPercentage' style={{width: atPercentage+"%"}}></span></span>
                        <span className={Math.sign(allData.atVariance) == -1 ? "currentStockVariance redValue" : "currentStockVariance"}><span>{formatters.CurrencyThousandSeparatorWithoutZero(allData.atVariance)}<span className='currentStockPercent'> ({atPercentage-100}%)</span></span><span className='currentStockCheck'>{allData.atPublished < (allData.preRegQnty+allData.retailQnty) ? <><span className='currentStockLink' onClick={() => props.handleClickFilter("", 29)}>{(allData.preRegQnty+allData.retailQnty) - allData.atPublished}</span><RedCheck /></> : <GreenCheck />}</span></span>
                    </div>
                    <div className="currentStockBarWrp">
                        <span className='currentStockTitle'><span>CAP Clean <span>({capQnty}/{allData.preRegQnty+allData.retailQnty})</span></span><span className='currentStockValue'>{formatters.CurrencyThousandSeparatorWithoutZero(allData.capValue)}</span></span>
                        <span className='currentStockBar'><span className='currentStockPercentage' style={{width: capPercentage+"%"}}></span></span>
                        <span className={Math.sign(allData.capVariance) == -1 ? "currentStockVariance redValue" : "currentStockVariance"}><span>{formatters.CurrencyThousandSeparatorWithoutZero(allData.capVariance)}<span className='currentStockPercent'> ({capPercentage-100}%)</span></span><span className='currentStockCheck'>{capQnty < (allData.preRegQnty+allData.retailQnty) ? <><span className='currentStockLink' onClick={() => props.handleClickFilter("", 6)}>{allData.noCapCode}</span><RedCheck /></> : <GreenCheck />}</span></span>
                    </div>
                </div>
                {
                    WindowWidths > 1200 ? <>
                    </> : <>
                        <LineWithIcon
                            title={"Total Overaged"}
                            value={allData.totalOveraged}
                            valueCurrency={formatters.MillionWithMWithoutZero(allData.totalOveragedValue)}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Total Risk"}
                            value={formatters.MillionWithMWithoutZero(Math.abs(Math.round(allData.totalRisk)))}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Franchise Overaged"}
                            value={allData.franOveraged}
                            valueCurrency={formatters.MillionWithMWithoutZero(allData.franOveragedValue)}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Franchise Risk"}
                            value={formatters.MillionWithMWithoutZero(Math.abs(Math.round(allData.franRisk)))}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Non-Fran Overaged"}
                            value={allData.nonFranOveraged}
                            valueCurrency={formatters.MillionWithMWithoutZero(allData.nonFranOveragedValue)}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Non-Fran Risk"}
                            value={allData.nonFranRisk}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                    </>
                }
            </div>
            {
                WindowWidths > 1200 ? <>
                    <div className='stockBoxRight'>
                        <span className='stockBoxLeftSubTitle'>OVERAGED PRIORITY LIST</span>
                        <span className='overagedValue'>{formatters.MillionWithMWithoutZero(Math.round(Math.abs(allData.franRisk + allData.nonFranRisk)))}</span>
                        <span className='stockBoxLeftSubTitle backText'>Total Writedown</span>
                        <span className='stockSmallText'>Excluding demo/courtesy</span>
                        <LineWithIcon
                            title={"Total OA"}
                            value={allData.totalOveraged}
                            valueCurrency={formatters.MillionWithMWithoutZero(allData.totalOveragedValue)}
                            iconType={"noIcon"}
                        />
                        <LineWithIcon
                            title={"Franchise OA"}
                            value={allData.franOveraged}
                            valueCurrency={formatters.MillionWithMWithoutZero(allData.franOveragedValue)}
                            iconType={"link"}
                            func={props.handleClickFilter}
                            funcType={"franNonFran"}
                            funcValue={25}
                        />
                        <LineWithIcon
                            title={"Franchise Risk"}
                            value={formatters.MillionWithMWithoutZero(Math.round(Math.abs(allData.franRisk)))}
                            valueCurrency={""}
                            iconType={"noIcon"}
                        />
                        <LineWithIcon
                            title={"Non-Fran OA"}
                            value={allData.nonFranOveraged}
                            valueCurrency={formatters.MillionWithMWithoutZero(allData.nonFranOveragedValue)}
                            iconType={"link"}
                            func={props.handleClickFilter}
                            funcType={"franNonFran"}
                            funcValue={26}
                        />
                        <LineWithIcon
                            title={"Non-Fran Risk"}
                            value={formatters.MillionWithMWithoutZero(Math.round(Math.abs(allData.nonFranRisk)))}
                            valueCurrency={""}
                            iconType={"noIcon"}
                        />
                        <LineWithIcon
                            title={"Demo/Courtesy Risk"}
                            value={formatters.MillionWithMWithoutZero(Math.abs(allData.demoCourtesyRisk))}
                            valueCurrency={""}
                            iconType={"noIcon"}
                        />
                    </div>
                </> : <></>
            }
        </div>
    );
}

export const UsedStockIndicators = (props) => {
    let {allData} = props;
    const [WindowWidths] = useWindowSize();

    const GraphCol = (props) => {
        return (
            <div className='graphColWrp'>
                <div className='graphColTop'>
                    <span className='graphColValue'>{props.value} <span>({props.valuePercent})</span></span>
                </div>
                <div className='graphCol' style={{
                    maxHeight: props.maxHeigh,
                    height: props.height,
                    background: props.bgColour,
                    cursor: "pointer"
                }} onClick={() => props.func(props.funcType, props.funcValue)}></div>
                <div className='graphColBtm'>
                    <span className='graphDesc' style={{background: props.textBackground, color: props.textColour}}>{props.text}</span>
                </div>
            </div>
        );
    }

    const PriceIndicator = (props) => {
        const priceArr = [props.data.priceIndicatorLow, props.data.priceIndicatorFair, props.data.priceIndicatorGood, props.data.priceIndicatorGreat, props.data.priceIndicatorHigh, props.data.priceIndicatorNoAnalysis];
        const priceIndicatorMax = priceArr.reduce((partialSum, a) => partialSum + a, 0);
        let higherValue =  priceArr.sort(function (a, b) { return a - b; });
        higherValue =  higherValue.slice(Math.max(higherValue.length - 1, 0));

        return (
            <>
                <div className='stockGraphIndicatorTop'>
                    <span className='stockGraphIndicatorTopTitle'>
                        Price indicator
                    </span>
                    <span className='stockGraphIndicatorTopSub'>
                        Advertised stock only
                    </span>
                </div>
                <div className='stockGraphIndicatorGraph'>
                    <GraphCol
                        value={props.data.priceIndicatorLow}
                        valuePercent={Math.round((props.data.priceIndicatorLow * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorLow !== 0 ? higherValue !== props.data.priceIndicatorLow ? Math.round((130 * props.data.priceIndicatorLow) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#D6D5D4"}
                        textColour={"#000"}
                        text={"Low"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={9}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorGreat !== 0 ? props.data.priceIndicatorGreat : 1}
                        valuePercent={Math.round((props.data.priceIndicatorGreat * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorGreat !== 0 ? higherValue !== props.data.priceIndicatorGreat ? Math.round((130 * props.data.priceIndicatorGreat) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#87C1A0"}
                        textColour={"#000"}
                        text={"Great"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={10}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorGood}
                        valuePercent={Math.round((props.data.priceIndicatorGood * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorGood !== 0 ? higherValue !== props.data.priceIndicatorGood ? Math.round((130 * props.data.priceIndicatorGood) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#1E6446"}
                        textColour={"#000"}
                        text={"Good"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={11}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorFair}
                        valuePercent={Math.round((props.data.priceIndicatorFair * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorFair !== 0 ? higherValue !== props.data.priceIndicatorFair ? Math.round((130 * props.data.priceIndicatorFair) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#FBBA00"}
                        textColour={"#000"}
                        text={"Fair"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={12}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorHigh}
                        valuePercent={Math.round((props.data.priceIndicatorHigh * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorHigh !== 0 ? higherValue !== props.data.priceIndicatorHigh ? Math.round((130 * props.data.priceIndicatorHigh) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#FF9A7A"}
                        textColour={"#000"}
                        text={"High"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={13}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorNoAnalysis}
                        valuePercent={Math.round((props.data.priceIndicatorNoAnalysis * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorNoAnalysis !== 0 ? higherValue !== props.data.priceIndicatorNoAnalysis ? Math.round((130 * props.data.priceIndicatorNoAnalysis) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#C7CACE"}
                        textBackground={""}
                        textColour={"#000"}
                        text={"No analysis"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={14}
                    />
                </div>
            </>
            
        );
    }

    const PerformanceRating = (props) => {
        const perfArr = [props.data.perfLow, props.data.perfBelowAvg, props.data.perfAboveAvg, props.data.perfExcellent, props.data.perfNoRating];
        const perfIndicatorMax = perfArr.reduce((partialSum, a) => partialSum + a, 0);
        let higherValue =  perfArr.sort(function (a, b) { return a - b; });
        higherValue =  higherValue.slice(Math.max(higherValue.length - 1, 0));

        return (
            <>
                <div className='stockGraphIndicatorTop'>
                    <span className='stockGraphIndicatorTopTitle'>
                        Performance Rating
                    </span>
                    <span className='stockGraphIndicatorTopSub'>
                        Advertised stock only
                    </span>
                </div>
                <div className='stockGraphIndicatorGraph'>
                    <GraphCol
                        value={props.data.perfLow}
                        valuePercent={Math.round((props.data.perfLow * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfLow !== 0 ? higherValue !== props.data.perfLow ? Math.round((130 * props.data.perfLow) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#ED4C3C"}
                        textBackground={"#FF3011"}
                        textColour={"#FFF"}
                        text={"Low"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={15}
                    />
                    <GraphCol
                        value={props.data.perfBelowAvg}
                        valuePercent={Math.round((props.data.perfBelowAvg * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfBelowAvg !== 0 ? higherValue !== props.data.perfBelowAvg ? Math.round((130 * props.data.perfBelowAvg) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#F5C426"}
                        textBackground={"#F5C426"}
                        textColour={"#FFF"}
                        text={"Below Avg"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={16}
                    />
                    <GraphCol
                        value={props.data.perfAboveAvg}
                        valuePercent={Math.round((props.data.perfAboveAvg * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfAboveAvg !== 0 ? higherValue !== props.data.perfAboveAvg ? Math.round((130 * props.data.perfAboveAvg) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#7CBF6A"}
                        textBackground={"#7CBF6A"}
                        textColour={"#FFF"}
                        text={"Above Avg"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={17}
                    />
                    <GraphCol
                        value={props.data.perfExcellent}
                        valuePercent={Math.round((props.data.perfExcellent * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfExcellent !== 0 ? higherValue !== props.data.perfExcellent ? Math.round((130 * props.data.perfExcellent) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#62AD54"}
                        textBackground={"#62AD54"}
                        textColour={"#FFF"}
                        text={"Excellent"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={18}
                    />
                    <GraphCol
                        value={props.data.perfNoRating}
                        valuePercent={Math.round((props.data.perfNoRating * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfNoRating !== 0 ? higherValue !== props.data.perfNoRating ? Math.round((130 * props.data.perfNoRating) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={""}
                        textColour={"#000"}
                        text={"No rating"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={19}
                    />
                </div>
            </>
        );
    }

    const RetailRating = (props) => {
        const perfArr = [props.data.retail20, props.data.retail2140, props.data.retail4160, props.data.retail6180, props.data.retai81];
        const perfIndicatorMax = perfArr.reduce((partialSum, a) => partialSum + a, 0);
        let higherValue =  perfArr.sort(function (a, b) { return a - b; });
        higherValue =  higherValue.slice(Math.max(higherValue.length - 1, 0));

        return (
            <>
                <div className='stockGraphIndicatorTop'>
                    <span className='stockGraphIndicatorTopTitle'>
                        Retail Rating
                    </span>
                    <span className='stockGraphIndicatorTopSub'>
                        Advertised stock only
                    </span>
                </div>
                <div className='stockGraphIndicatorGraph'>
                    <GraphCol
                        value={props.data.retail20}
                        valuePercent={Math.round((props.data.retail20 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail20 !== 0 ? higherValue !== props.data.retail20 ? Math.round((130 * props.data.retail20) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#ED4C3C"}
                        textBackground={"#FF3011"}
                        textColour={"#FFF"}
                        text={"< 20"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={20}
                    />
                    <GraphCol
                        value={props.data.retail2140}
                        valuePercent={Math.round((props.data.retail2140 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail2140 !== 0 ? higherValue !== props.data.retail2140 ? Math.round((130 * props.data.retail2140) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#F5C426"}
                        textBackground={"#F5C426"}
                        textColour={"#FFF"}
                        text={"21 - 40"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={21}
                    />
                    <GraphCol
                        value={props.data.retail4160}
                        valuePercent={Math.round((props.data.retail4160 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail4160 !== 0 ? higherValue !== props.data.retail4160 ? Math.round((130 * props.data.retail4160) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#F9F562"}
                        textBackground={"#F9F562"}
                        textColour={"#A59F9F"}
                        text={"41 - 60"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={22}
                    />
                    <GraphCol
                        value={props.data.retail6180}
                        valuePercent={Math.round((props.data.retail6180 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail6180 !== 0 ? higherValue !== props.data.retail6180 ? Math.round((130 * props.data.retail6180) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#7CBF6A"}
                        textBackground={"#7CBF6A"}
                        textColour={"#FFF"}
                        text={"61 - 80"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={23}
                    />
                    <GraphCol
                        value={props.data.retai81}
                        valuePercent={Math.round((props.data.retai81 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retai81 !== 0 ? higherValue !== props.data.retai81 ? Math.round((130 * props.data.retai81) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#62AD54"}
                        textColour={"#FFF"}
                        text={"> 81"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={24}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            {
                WindowWidths > 1200 ? <>
                    <div className="stockBoxWrp">
                        <PriceIndicator 
                            data={allData}
                            handleClickFilter={props.handleClickFilter}
                        />
                    </div>
                    <div className="stockBoxWrp">
                        <PerformanceRating 
                            data={allData}
                            handleClickFilter={props.handleClickFilter}
                        />
                    </div>
                    <div className="stockBoxWrp">
                        <RetailRating 
                            data={allData}
                            handleClickFilter={props.handleClickFilter}
                        />
                    </div>
                </> : <>
                    <div className="stockBoxWrp swiperWrp">
                        <div className='usedStockSwiper'>
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                centeredSlides={true}
                            >
                                <SwiperSlide>
                                    <PriceIndicator 
                                        data={allData}
                                        handleClickFilter={props.handleClickFilter}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <PerformanceRating 
                                        data={allData}
                                        handleClickFilter={props.handleClickFilter}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <RetailRating 
                                        data={allData}
                                        handleClickFilter={props.handleClickFilter}
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </>
            }
        </>
    );
}