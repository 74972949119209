import { useState, useEffect } from 'react';
import { Grid, Input } from '@material-ui/core';
import StockReportFilters from './usedStockReportFilters';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import {
    postGetUsedStockReport,
    postUpdateUsedStockReport
} from '../../../Core/Service/Nicol_Common_services';
import UsedStockReportTable from './usedStockReportTable';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import formatters from '../../../Core/Grid/inputFormatter';
import { UsedStockReportTotalStock, UsedStockReportToDo, UsedStockCurrentValue, UsedStockIndicators } from './usedStockReportComponents';
import CapClean from '../UsedVehicleStock/CapClean';
import './usedStockReportTable.scss';
import './usedStockReportComponents.scss';

const UsedStockReport = () => {
    const [selectedBranchList, setSelectedBranchList] = useState([]);
    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
    const [data, setData] = useState({
        tableLoaderVisible: true,
        topDataLoaderVisible: true,
        reloadFilters: false
    });

    const [changes, setChanges] = useState({
        priceUpdate: 0,
        milaegeUpdate: 0
    })

    const [editPricePopup, setEditPricePopup] = useState(false);
    const [confirmEditPricePopup, setConfirmEditPricePopup] = useState(false);
    const [topRefreshBanner, setTopRefreshBanner] = useState(false);
    const [capPopup, setCapPopup] = useState(false);
    const [capPopupSearch, setCapPopupSearch] = useState("");

    const getFilterData = async (value) => {
        setData((st) => ({ ...st, tableLoaderVisible: true, topDataLoaderVisible: true, branchID: value.branchID, vehicleProfileIds: value.vehicleProfileIds, daysInStock: value.daysInStock, fuelType: value.fuelType, franNonFran: value.franNonFran, modelCodes: value.modelCodes }));
        let dataSearch = {
            branchID: value.branchID || [],
            vehicleProfileIds: value.vehicleProfileIds || [],
            daysInStock: value.daysInStock || [],
            fuelType: value.fuelType || [],
            franNonFran: value.franNonFran || [],
            modelCodes: value.modelCodes || []
        };
        let res = await postGetUsedStockReport(dataSearch);

        if (res.success) {
            setData((st) => ({
                ...st,
                allDataAfterRequest: res.data,
                stockReportData: res.data.usedStockReportData,
                tableLoaderVisible: false,
                topDataLoaderVisible: false,
                reloadFilters: false
            }));
        }
    };

    const getSelectedBranchList = (list) => {
        setSelectedBranchList(list || []);
    };

    const updatePrice = async (row, branchID, regNo, stockNo, retailPrice, mileage, description, site, lastUpdateMileage, lasUpdatePrice, priceUpdateUser, mileageUpdateUser) => {
        setData((st) => ({
            ...st,
            branchIDUpdate: branchID,
            regNo: regNo,
            stockNo: stockNo,
            retailPrice: retailPrice,
            mileage: mileage,
            description: description,
            site: site,
            lastUpdateMileage: lastUpdateMileage,
            lasUpdatePrice: lasUpdatePrice,
            priceUpdateUser: priceUpdateUser,
            mileageUpdateUser: mileageUpdateUser,
            rowUpdated: row
        }));
        setConfirmEditPricePopup(false);
        setEditPricePopup(true);
    }

    const handleSavePrice = async () => {
        setData((st) => ({ 
            ...st,
            tableLoaderVisible: true
        }));

        let dataUpdateSearch = {
            branchID: data.branchID || [],
                vehicleProfileIds: data.vehicleProfileIds || [],
                daysInStock: data.daysInStock || [],
                fuelType: data.fuelType || [],
                franNonFran: data.franNonFran || [],
                modelCodes: data.modelCodes || [],

            regNo: data.regNo,
            stockNo: data.stockNo,
            retailPrice: data.retailPrice,
            mileage: data.mileage,
            updatedRetailPrice: changes.priceUpdate,
            updatedMileage: changes.milaegeUpdate,
            userName: localStorage.getItem('_displayName_'),
            branchIDUpdate: data.branchIDUpdate,
        };

        let res = await postUpdateUsedStockReport(dataUpdateSearch);

        if (res.success) {
            setConfirmEditPricePopup(false)
            setEditPricePopup(false);
            setTopRefreshBanner(true);

            setChanges((st) => ({
                ...st,
                priceUpdate: 0,
                milaegeUpdate: 0
            }));

            setData((st) => ({ 
                ...st,
                stockReportData: res.data.usedStockReportData.value.data.usedStockReportData,
                tableLoaderVisible: false
            }));

            let scrollTablePositon = JSON.parse(localStorage.getItem('NicoleFilterData'))?.scrollTablePosition;
            var myElement = document.getElementById('tableStockScroll');
            var topPos = myElement.offsetTop;
            myElement.scrollTop = scrollTablePositon;

            setTimeout(function(){
                document.getElementById(data.rowUpdated).classList.add("active");
                document.getElementById(data.rowUpdated).getElementsByClassName("spanEdit")[0].classList.add("hidden");
                document.getElementById(data.rowUpdated).getElementsByClassName("checkedIcon")[0].classList.remove("hidden");
            }, 1000);

            setTimeout(function(){
                document.getElementById(data.rowUpdated).classList.remove("active");
                document.getElementById(data.rowUpdated).getElementsByClassName("spanEdit")[0].classList.remove("hidden");
                document.getElementById(data.rowUpdated).getElementsByClassName("checkedIcon")[0].classList.add("hidden");
            }, 5000);
        }
    }

    const refreshResults = async () => {
        setTopRefreshBanner(false);

        setData((st) => ({ 
            ...st,
            tableLoaderVisible: true, 
            topDataLoaderVisible: true
        }));

        let dataSearch = {
            branchID: data.branchID || [],
            vehicleProfileIds: data.vehicleProfileIds || [],
            daysInStock: data.daysInStock || [],
            fuelType: data.fuelType || [],
            franNonFran: data.franNonFran || [],
            modelCodes: data.modelCodes || []
        };

        let res = await postGetUsedStockReport(dataSearch);
        
        if (res.success) {
            setData((st) => ({
                ...st,
                allDataAfterRequest: res.data,
                stockReportData: res.data.usedStockReportData,
                tableLoaderVisible: false,
                topDataLoaderVisible: false,
                reloadFilters: false
            }));
        }
    }

    const handleSaveFirstStage = () => {
        if (changes.priceUpdate !== 0 || changes.priceUpdate === 0) {
            let retailChange = changes.priceUpdate - data.retailPrice;
    
            if (retailChange >= 1000 || retailChange <= -1000) {
                setConfirmEditPricePopup(true);
            }
            else {
                handleSavePrice();
            }
        }
    }

    const handlePriceChange = (e) => {
        setChanges((st) => ({
            ...st,
            priceUpdate: e.target.value
        }));
    }

    const handleMileageChange = (e) => {
        setChanges((st) => ({
            ...st,
            milaegeUpdate: e.target.value
        }));
    }

    const handleClosePopup = () => {
        setEditPricePopup(!editPricePopup);
        setChanges((st) => ({
            ...st,
            priceUpdate: 0,
            milaegeUpdate: 0
        }));

    }

    const handleClickUpdateFilter = async (type, option) => {
        setData((st) => ({...st, reloadFilters: true}));

        if (type === "daysInStock") {
            let daysStock = [];
            daysStock.push(option);


            let dataSearch = {
                branchID: data.branchID || [],
                vehicleProfileIds: data.vehicleProfileIds || [],
                daysInStock: daysStock || [],
                fuelType: data.fuelType || [],
                franNonFran: data.franNonFran || [],
                modelCodes: data.modelCodes || [],
            };

            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, daysInStockIds: daysStock }));
    
            getFilterData(dataSearch);
        }

        if (type === "vehicleStatus") {
            let vecProfile = [];
            vecProfile.push(option);

            let dataSearch = {
                branchID: data.branchID || [],
                vehicleProfileIds: vecProfile || [],
                daysInStock: data.daysInStock || [],
                fuelType: data.fuelType || [],
                franNonFran: data.franNonFran || [],
                modelCodes: data.modelCodes || [],
            };

            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleIdState: true, VehicleProfileIds: vecProfile }));
    
            getFilterData(dataSearch);
        }
    }

    const handleClickUpdateTableFilter = (type, option) => {
        var filterType = option >= 1 && option <= 8 ? "actionFilter" : "metricsFilter";

        var filterName = option === 1 ? "No Photos" : 
                           option === 2 ? "No Att Grabber" :
                           option === 3 ? "To Prep" :
                           option === 4 ? "Price Change" :
                           option === 5 ? "No Price" :
                           option === 6 ? "No CAP Code" :
                           option === 7 ? "Low Perf. Rating" :
                           option === 8 ? "Reserved 3+Day" :
                           option === 9 ? "- Low" :
                           option === 10 ? "- Great" :
                           option === 11 ? "- Good" :
                           option === 12 ? "- Fair" :
                           option === 13 ? "- High" :
                           option === 14 ? "- No Analysis" :
                           option === 15 ? "- Low" :
                           option === 16 ? "- Below Avg" :
                           option === 17 ? "- Above Avg" :
                           option === 18 ? "- Excellent" :
                           option === 19 ? "- No Rating" :
                           option === 20 ? "- 20" :
                           option === 21 ? "- 21-40" :
                           option === 22 ? "- 41-60" :
                           option === 23 ? "- 61-80" :
                           option === 24 ? "- 81" :
                           option === 25 ? "- Franchise Risk" :
                           option === 26 ? "- Non Franchise Risk" : 
                           option === 27 ? "Published" :
                           option === 28 ? "None" : 
                           option === 29 ? "Not on AT/Not Published" : 
                           option === 30 ? "Reserved" :"" ;

        var filterInputName = option === 1 ? "noPhotos" : 
                           option === 2 ? "noAttGrabber" :
                           option === 3 ? "toPrep" :
                           option === 4 ? "priceChange" :
                           option === 5 ? "noPrice" :
                           option === 6 ? "noCapCode" :
                           option === 7 ? "lowLeads" :
                           option === 8 ? "reserved" : 
                           option === 9 ? "priceLow" :
                           option === 10 ? "priceGreat" :
                           option === 11 ? "priceGood" :
                           option === 12 ? "priceFair" :
                           option === 13 ? "priceHigh" :
                           option === 14 ? "priceNoAna" :
                           option === 15 ? "perfLow" :
                           option === 16 ? "perfBelowAvg" :
                           option === 17 ? "perfAboveAvg" :
                           option === 18 ? "perfExcellent" :
                           option === 19 ? "perfNoRating" :
                           option === 20 ? "retail<20" :
                           option === 21 ? "retail2160" :
                           option === 22 ? "retail4160" :
                           option === 23 ? "retail6180" :
                           option === 24 ? "retail>81" :
                           option === 25 ? "franchiseRisk" :
                           option === 26 ? "nonFranchiseRisk" : 
                           option === 27 ? "published" :
                           option === 28 ? "none" : 
                           option === 29 ? "notInAt" : 
                           option === 30 ? "reservedMargin" : "" ;

        var filterInputId = option === 1 ? "noPhotosInput" : 
                           option === 2 ? "noAttGrabberInput" :
                           option === 3 ? "toPrepInput" :
                           option === 4 ? "priceChangeInput" :
                           option === 5 ? "noPriceInput" :
                           option === 6 ? "noCapCodeInput" :
                           option === 7 ? "lowLeadsInput" :
                           option === 8 ? "reserved10Day" :
                           option === 9 ? "priceLowInput" :
                           option === 10 ? "priceGreatInput" :
                           option === 11 ? "priceGoodInput" :
                           option === 12 ? "priceFairInput" :
                           option === 13 ? "priceHighInput" :
                           option === 14 ? "priceNoAnaInput" :
                           option === 15 ? "perfLowInput" :
                           option === 16 ? "perfBelowAvgInput" :
                           option === 17 ? "perfAboveAvgInput" :
                           option === 18 ? "perfExcellentInput" :
                           option === 19 ? "perfNoRatingInput" :
                           option === 20 ? "retail<20Input" :
                           option === 21 ? "retail2140Input" :
                           option === 22 ? "retail4160Input" :
                           option === 23 ? "retail6180Input" :
                           option === 24 ? "retail>81Input" : 
                           option === 25 ? "franchiseRiskInput" :
                           option === 26 ? "nonFranchiseRiskInput" : 
                           option === 27 ? "publishedInput" :
                           option === 28 ? "notPublishedInput" :
                           option === 29 ? "notInAtInput" : 
                           option === 30 ? "reservedMarginInput" : "" ;
        
        document.getElementById(filterType).innerHTML = filterName;
        document.getElementsByName(filterType).value = filterInputName;
        document.getElementById(filterInputId).click();
        
        let element = document.querySelector("#tableStockScroll");

        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    const openCapPopup = (stockID) => {
        setCapPopupSearch(stockID)
        setCapPopup(true);
    }

    const closeCapPopup = () => {
        setCapPopupSearch("");
        setCapPopup(false);
    }

    return (
        <div className="boxSkel">
            <Grid container spacing={1} alignContent="center" justifyContent="space-between">
                <Grid item xs style={{ textAlign: 'left' }}>
                    <div className="titleBoxSkel">
                        Used Stock Report
                    </div>
                </Grid>
            </Grid>
            {
                data.topDataLoaderVisible ? "" : (
                    <>
                        <div className='lastUpdatedWrp'>
                            <span className='lastUpdated'>AT Data Last Update: <span>{data.allDataAfterRequest.lastUpdated.toString().slice(0, 10).split("-").reverse().join("/") + " - " +data.allDataAfterRequest.lastUpdated.toString().slice(11, 16)}</span></span>
                        </div>
                    </>
                )
            }

            {
                data.reloadFilters ? (
                    <CircularIndeterminate type="small" />
                ) : (
                    <>
                        <StockReportFilters getFilterData={getFilterData} getSelectedBranchList={getSelectedBranchList} />
                    </>
                )
            }

            {
                topRefreshBanner ? (<>
                    <div className='topBannerNotice'>
                        <span>Table updated! <br/><span onClick={() => refreshResults()}>Click here</span> to update the graphics.</span>
                    </div>
                </>) : (<></>)
            }

                {
                    data.topDataLoaderVisible ? (
                        <CircularIndeterminate type="small" />
                    ) : (
                        <>
                            <div className='stockRowWrp'>
                                <UsedStockReportTotalStock allData={data.allDataAfterRequest} handleClickFilter={handleClickUpdateFilter} />
                                <UsedStockReportToDo allData={data.allDataAfterRequest} handleClickFilter={handleClickUpdateTableFilter} />
                                <UsedStockCurrentValue allData={data.allDataAfterRequest} handleClickFilter={handleClickUpdateTableFilter} />
                                <UsedStockIndicators allData={data.allDataAfterRequest} handleClickFilter={handleClickUpdateTableFilter} />
                            </div>  
                        </>
                    )
                }

            {
                capPopup ? 
                    <CapClean stockID={capPopupSearch} onClose={closeCapPopup} isEditable={true} /> 
                    : null
            }

            {
                editPricePopup ? (
                    <DialogComp className="popupUsedVehicleReport" maxWidth="lg" onClose={handleClosePopup}>  
                        {
                            confirmEditPricePopup? <>
                                <div className='popupUseVehicleConfirmation'>
                                    <span className='confirmIcon'>!</span>
                                    <span className='confirmText'>Are you sure you want to make this change?</span>
                                    <span className='confirmSub'>This is a larger price change than average, please confirm the below before saving the update.</span>
                                    <div className='confirmPriceRange'>
                                        <span className='value'>{formatters.CurrencyThousandSeparatorWithoutZero(data.retailPrice)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
                                            <path d="M21.8536 4.35358C22.0488 4.15832 22.0488 3.84174 21.8536 3.64648L18.6716 0.464492C18.4763 0.26923 18.1597 0.269229 17.9645 0.464491C17.7692 0.659753 17.7692 0.976335 17.9645 1.1716L20.7929 4.00003L17.9645 6.82845C17.7692 7.02371 17.7692 7.3403 17.9645 7.53556C18.1597 7.73082 18.4763 7.73082 18.6716 7.53556L21.8536 4.35358ZM-6.94299e-07 4.5L21.5 4.50003L21.5 3.50003L6.94299e-07 3.5L-6.94299e-07 4.5Z" fill="black"/>
                                        </svg>
                                        <span className={changes.priceUpdate >= data.retailPrice ? 'change green' : 'change red' }>
                                            {changes.priceUpdate >= data.retailPrice ? "+" : ""}
                                            {formatters.CurrencyThousandSeparatorWithoutZero(changes.priceUpdate - data.retailPrice)}
                                            <span>CHANGE</span>
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
                                            <path d="M21.8536 4.35358C22.0488 4.15832 22.0488 3.84174 21.8536 3.64648L18.6716 0.464492C18.4763 0.26923 18.1597 0.269229 17.9645 0.464491C17.7692 0.659753 17.7692 0.976335 17.9645 1.1716L20.7929 4.00003L17.9645 6.82845C17.7692 7.02371 17.7692 7.3403 17.9645 7.53556C18.1597 7.73082 18.4763 7.73082 18.6716 7.53556L21.8536 4.35358ZM-6.94299e-07 4.5L21.5 4.50003L21.5 3.50003L6.94299e-07 3.5L-6.94299e-07 4.5Z" fill="black"/>
                                        </svg>
                                        <span className='value'>{formatters.CurrencyThousandSeparatorWithoutZero(changes.priceUpdate)}</span>
                                    </div>
                                    <div className='confirmPriceBtns'>
                                        <span className='confirm' onClick={handleSavePrice}>YES</span>
                                        <span onClick={handleClosePopup}>NO</span>
                                    </div>
                                </div>
                            </> : <>
                                <div className='popupUseVehicleFirstStage'>
                                    <span className='popupTitle'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M9.29388 3.42704L5.74481 3.44603C3.13134 3.46001 1.02409 5.59943 1.03814 8.22456L1.07627 15.3544C1.09031 17.9795 3.22033 20.0963 5.83379 20.0823L12.9319 20.0443C15.5454 20.0304 17.6526 17.8909 17.6386 15.2658L17.6132 10.5126M13.2995 3.53462L8.29347 9.25727C8.20263 9.3618 8.14925 9.48866 8.14391 9.61266L8.15888 12.348C8.14644 12.637 8.56095 12.9973 8.85999 12.9278L11.5187 12.3102C11.647 12.2804 11.7686 12.2058 11.8595 12.1013L16.9594 5.94779M13.2995 3.53462L15.0178 1.46134C15.4176 1.00121 16.0657 0.87633 16.4652 1.18241L18.4586 2.75209C18.8581 3.05817 18.8579 3.67931 18.4581 4.13944L16.9594 5.94779M13.2995 3.53462C13.713 4.52158 15.024 6.38595 16.9594 5.94779M4.6317 16.5237L14.0959 16.4731" stroke="#3E3E3E" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>  
                                        VEHICLE EDIT  
                                    </span> 
                                    <span className='popupVehicleDesc'>{data.description}</span>
                                    <span className='vehicleDetail'>REG: <span>{data.regNo}</span></span>
                                    <span className='vehicleDetail'>STOCK NO: <span>{data.stockNo}</span></span>
                                    <span className='vehicleDetail'>SITE: <span>{data.site}</span></span>
                                    <div className='vehicleDetailsUpdate'>
                                        <div className='vehicleDetailsUpdateLeft'>
                                            <span className='vehicleCurrentValue'>{formatters.CurrencyThousandSeparatorWithoutZero(data.retailPrice)}</span>
                                            <span className='vehicleCurrentTitle'>CURRENT PRICE</span>
                                            <span className='vehicleLastUpdated'>Last updated on {data.lasUpdatePrice}</span>
                                            <span className='vehicleLastUpdated'>By {data.priceUpdateUser}</span>
                                            <div className='updateBoxWrp'>
                                                <span className='updateBoxTitle'>UPDATED PRICE</span>
                                                <div className='updateBoxInr'>
                                                    <Input type='number' value={changes.priceUpdate !== 0 ? changes.priceUpdate : data.retailPrice} onChange={handlePriceChange} />
                                                    {
                                                        changes.priceUpdate !== 0 ? <>
                                                            <span className={changes.priceUpdate >= data.retailPrice ? "updateBoxDif green" : "updateBoxDif red"}>
                                                                {changes.priceUpdate >= data.retailPrice ? "+" : ""}
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(changes.priceUpdate - data.retailPrice)}
                                                            </span>
                                                        </> : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='vehicleDetailsUpdateRight'>
                                            <span className='vehicleCurrentValue'>{formatters.ThousandSeparatorWithoutZero(data.mileage)}</span>
                                            <span className='vehicleCurrentTitle'>CURRENT MILEAGE</span>
                                            <span className='vehicleLastUpdated'>Last updated on {data.lastUpdateMileage}</span>
                                            <span className='vehicleLastUpdated'>By {data.mileageUpdateUser}</span>
                                            <div className='updateBoxWrp'>
                                                <span className='updateBoxTitle'>UPDATED MILEAGE</span>
                                                <div className='updateBoxInr'>
                                                    <Input type='number' value={changes.milaegeUpdate !== 0 ? changes.milaegeUpdate : data.mileage} onChange={handleMileageChange} />
                                                    {
                                                        changes.milaegeUpdate !== 0 ? <>
                                                            <span className={changes.milaegeUpdate >= data.mileage ? "updateBoxDif green" : "updateBoxDif red"}>
                                                                {changes.milaegeUpdate >= data.mileage ? "+" : ""}
                                                                {formatters.ThousandSeparatorWithoutZero(changes.milaegeUpdate - data.mileage)}
                                                            </span>
                                                        </> : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='popupSaveButtons'>
                                        <span className='save' onClick={handleSaveFirstStage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M7.97811 21.1723V15.8655C7.97811 15.2142 8.50609 14.6862 9.1574 14.6862H17.4124C18.0637 14.6862 18.5917 15.2142 18.5917 15.8655V21.7619M18.5917 4.6622V7.02079C18.5917 7.67209 18.0637 8.20008 17.4124 8.20008L9.1574 8.20008C8.50609 8.20008 7.97811 7.67209 7.97811 7.02078L7.97811 3.48291M22.1271 7.60795L18.5942 4.07504C18.2151 3.69591 17.7009 3.48291 17.1647 3.48291H5.87223C4.75569 3.48291 3.85059 4.38802 3.85059 5.50455V20.3299C3.85059 21.4465 4.75569 22.3516 5.87223 22.3516H20.6976C21.8141 22.3516 22.7193 21.4465 22.7193 20.3299V9.03746C22.7193 8.50129 22.5063 7.98708 22.1271 7.60795Z" stroke="#6D54D7" strokeWidth="2" strokeLinecap="round"/>
                                            </svg>
                                            Save Update
                                        </span>
                                        <span onClick={handleClosePopup}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M9.78302 0.482422C14.9905 0.48341 19.2119 4.70604 19.2129 9.91503C19.2139 15.1292 14.9885 19.3531 9.77388 19.3508C4.55551 19.3489 0.340041 15.1208 0.344241 9.89353C0.348442 4.69937 4.57873 0.481434 9.78302 0.482422ZM9.76004 18.2113C14.3192 18.2298 18.0412 14.5332 18.0652 9.96296C18.0894 5.37835 14.4037 1.64667 9.82775 1.62271C5.22955 1.59849 1.50874 5.2936 1.49144 9.90144C1.47415 14.4764 5.17222 18.1928 9.76004 18.2113Z" fill="#6366F1"/>
                                                <path d="M9.78144 9.13377C9.83605 9.0678 9.8694 9.02011 9.90968 8.97984C10.9776 7.91071 12.0457 6.84184 13.1153 5.77419C13.4397 5.45027 13.9492 5.55726 14.0817 5.97828C14.155 6.21178 14.0908 6.4129 13.9171 6.58635C12.9258 7.57493 11.9367 8.56573 10.9464 9.55554C10.8301 9.67191 10.7122 9.78706 10.5593 9.93802C10.6178 9.98003 10.6771 10.0114 10.7226 10.0566C11.7826 11.1164 12.8408 12.1776 13.8993 13.2385C14.1279 13.4676 14.1691 13.7591 14.007 13.9983C13.7992 14.305 13.3918 14.3393 13.1198 14.0682C12.5157 13.4668 11.9138 12.8635 11.3111 12.2608C10.8308 11.7805 10.35 11.3002 9.87064 10.8189C9.84198 10.79 9.82196 10.7527 9.7933 10.7131C9.74364 10.7593 9.71349 10.7853 9.68533 10.8134C8.60484 11.8934 7.52484 12.9737 6.44435 14.0537C6.24298 14.255 5.99763 14.3054 5.76957 14.1948C5.40611 14.0186 5.33322 13.557 5.62676 13.2625C6.44064 12.4459 7.25651 11.6313 8.07213 10.8164C8.36887 10.5199 8.66661 10.2244 8.97002 9.92246C8.93321 9.88317 8.90405 9.85006 8.87292 9.81893C7.79861 8.74437 6.72528 7.66907 5.64899 6.59673C5.47604 6.42427 5.40166 6.2271 5.46887 5.99187C5.58846 5.57381 6.10461 5.44508 6.42162 5.75492C6.76605 6.0917 7.10381 6.43539 7.44454 6.77611C8.18702 7.51859 8.9295 8.26083 9.67149 9.0038C9.70336 9.03568 9.73005 9.07274 9.78144 9.13402V9.13377Z" fill="#6366F1"/>
                                            </svg>
                                            Cancel
                                        </span>
                                    </div>
                                </div>
                            </>
                        }
                    </DialogComp>
                ) : null
            }

            <Grid>
                {
                    data.tableLoaderVisible ? (
                        <CircularIndeterminate type="small" />
                    ) : (
                        <>
                            <UsedStockReportTable StockReportDataTable={data.stockReportData} updatePrice={updatePrice} openCapPopup={openCapPopup} />
                        </>
                    )
                }
            </Grid>
        </div>
    )
}

export default UsedStockReport;